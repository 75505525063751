<template>
  <i 
    class="fax-status-icon fa fa-level-up fa-lg" 
    :class="faxStatusClasses" 
  />
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { FaxStatus } from '@/types/fax-status.type';

export default defineComponent({
  props: {
    faxStatus: {
      type: String as PropType<FaxStatus>,
      required: false,
      default: null
    }
  },
  setup(props) {
    const faxStatusClasses = computed(() => ({
      'in-progress': props?.faxStatus === 'In Progress',
      sent: props?.faxStatus === 'Sent',
      failed: props?.faxStatus === 'Failed'
    }))
    return {
      faxStatusClasses
    };
  }
});
</script>

<style lang="scss" scoped>
.fax-status-icon {
  color: inherit;
}
.in-progress {
  color: yellow;
}
.sent {
  color: green;
}
.failed {
  color: red;
}
</style>