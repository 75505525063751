<template>
  <div class="first-page-checkbox">
    <base-checkbox 
      :modelValue="modelValue"
      @update:modelValue="(value) => $emit('update:modelValue', value)"
    />
    <base-typography caption> Only 1st Page? </base-typography>
    <base-tooltip description="Only sends first page of the PDF form">
      <i class="fa fa-question-circle" />
    </base-tooltip>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import BaseCheckbox from '@/ui-library/base-checkbox/base-checkbox.vue';
import BaseTypography from '@/ui-library/base-typography/base-typography.vue';
import BaseTooltip from '@/ui-library/base-tooltip/base-tooltip.vue';

export default defineComponent({
  components: { BaseCheckbox, BaseTypography, BaseTooltip },
  props: {
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    return {};
  }
});
</script>

<style lang="scss" scoped>
.first-page-checkbox {
  display: flex;
  align-items: center;
  gap: 0.3em;
}
</style>