import http from '@/http/http';
import { createEndpointForEditingPatientRequisition } from '../../../services/requisition-service-helpers';
import { OutgoingFaxRecipient } from '../types/outgoing-fax-recipient.interface';

export async function saveRequisitionAnnotations(
  filledPdfId?: number,
  xfdfString?: string
): Promise<void> {
  if (!filledPdfId) throw Error('Saving requisition requires a pdfFormId');
  if (!xfdfString) throw Error('Saving requisition requires an xfdfString');
  const response = await http.post<XMLHttpRequest>(
    createEndpointForEditingPatientRequisition(filledPdfId),
    `data=${xfdfString}`,
    { headers: http.createHeaderForXMLHttpRequest() }
  );
  http.fulfillXmlHttpRequest(response);
}

const initiateAttachmentsFeature = (attachmentsUrl: string) =>
  http.callGetXmlPayload(attachmentsUrl);
const updateReleaseToConnectStatus = (releaseToConnectUrl: string) =>
  http.callPatchXmlPayload(releaseToConnectUrl);
const deleteRequisition = (deleteUrl: string) =>
  http.callDeleteXmlPayload(deleteUrl);
const getFaxInformationUrl = (faxInfomationUrl: string) =>
  http.get<OutgoingFaxRecipient[]>(faxInfomationUrl, { params: { format: 'json' } });

export default {
  saveRequisitionAnnotations,
  initiateAttachmentsFeature,
  updateReleaseToConnectStatus,
  deleteRequisition,
  getFaxInformationUrl
}
