import { replaceQueryParamsOnPathname } from '../../../../../utils/route-helpers';
import { shouldOpenVideoLinkAccordingToQueryParam } from '../types/video-link.functions';

export function useQueryParameterManager(toggleVideoLink: (status?: boolean) => void) {
  const urlSearchParams = new URLSearchParams(window.location.search);

  const deleteQueryParam = (queryParams: URLSearchParams, key: string) => {
    queryParams.delete(key);
    window
      .history
      .replaceState({}, '', replaceQueryParamsOnPathname(window.location.pathname, queryParams));
  }

  if (shouldOpenVideoLinkAccordingToQueryParam(urlSearchParams)) {
    toggleVideoLink(true);
    deleteQueryParam(urlSearchParams, 'video-link');
  }
}