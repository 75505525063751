<template>
  <base-button warning wide>
    cancel
  </base-button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import BaseButton from '@/ui-library/base-button/base-button.vue';

export default defineComponent({
  components: { BaseButton }
});
</script>