import consumer from "../consumer"

window.subscribeToTranscription = function(transcriptionId) {
  subscribeToSubscription(transcriptionId);
}

const subscribeToSubscription = function(transcriptionId) {
  // In some cases the JS can have a blank transcriptionId and throw an error on the
  // subscribe to channel list
  if (!transcriptionId) {
    return true
  }
  let channelId = `{\"channel\":\"EmrChannels::TranscriptionChannel\",\"transcription_id\":${transcriptionId}}`
  let content = document.querySelector('#transcription-audio-files-index')
  console.log('subscribed to transcription')
  if (isSubscribed(consumer, channelId)) {
    return true
  }
  consumer.subscriptions.create(
    {
      channel: 'EmrChannels::TranscriptionChannel',
      id: transcriptionId
    },
    {
      initialized() {

      },
      received(data) {
        switch(data['messageType']) {
          case 'transcription_audio_file_status_update':
            transcriptionAudioFileStatusUpdate(data['id'], data['status'], data['message']);
            break;
          case 'transcription_data_update':
            transcriptionDataUpdate(data['message']);
            break;
        }
      },

      // Call when subscription is ready for use on the server
      connected() {

      },
    }
  );
};

const unsubscribeFromThread = function(transcriptionId) {
  let channelId = `{\"channel\":\"EmrChannels::InstantMessengerThreadChannel\",\"thread_id\":${transcriptionId}}`
  let subscriptions = consumer.subscriptions.findAll(channelId)
  subscriptions.forEach(subscription => consumer.subscriptions.remove(subscription))
};

function isSubscribed(consumer, channelId) {
  return consumer.subscriptions.findAll(channelId).length > 0
}

function transcriptionAudioFileStatusUpdate(id, status, message) {
  let transcriptionAudioFileContainer = $(`[data-transcription-audio-file-id="${id}"]`)[0];
  transcriptionAudioFileContainer.textContent = message;

  switch(status) {
    case 'Completed':
      transcriptionAudioFileContainer.classList.add('status-completed');
      transcriptionAudioFileContainer.classList.remove('status-error');
      transcriptionAudioFileContainer.classList.remove('status-in-progress');
      transcriptionAudioFileContainer.classList.remove('status-pending');
      break;
    case 'Error':
      transcriptionAudioFileContainer.classList.add('status-error');
      transcriptionAudioFileContainer.classList.remove('status-completed');
      transcriptionAudioFileContainer.classList.remove('status-in-progress');
      transcriptionAudioFileContainer.classList.remove('status-pending');
      break;
    case 'In Progress':
      transcriptionAudioFileContainer.classList.add('status-in-progress');
      transcriptionAudioFileContainer.classList.remove('status-error');
      transcriptionAudioFileContainer.classList.remove('status-completed');
      transcriptionAudioFileContainer.classList.remove('status-pending');
      break;
    case 'Pending':
      transcriptionAudioFileContainer.classList.add('status-pending');
      transcriptionAudioFileContainer.classList.remove('status-error');
      transcriptionAudioFileContainer.classList.remove('status-in-progress');
      transcriptionAudioFileContainer.classList.remove('status-completed');
      break;
  }
}

function transcriptionDataUpdate(message) {
  let transcriptionDataContainer = document.querySelector('#transcription-data');
  transcriptionDataContainer.innerHTML = message;
}
