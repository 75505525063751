/**
 * 
 * GLOBAL STATE FACADE is an API on the global context that allows code 
 * served from Rails to control state management in vue. ie. Whenever a 
 * Rails partial is initiated, we can access this global api to run a 
 * function that can inititate a state change via our state manager (Pinia); 
 * 
 * This is only necessary because we are migrating a full Rails view 
 * architecture to Vue. 
 *
 */

import { createGlobalStateFacade } from './types/global-state-facade.functions';

export default {
  install: (app) => {
    (window as any).globalStateFacade = createGlobalStateFacade();
  }
}