<template>
  <patient-chart-panel-container>
    <requisition-panel-header
      @viewHistory="changePage('history')" 
      @newRequisition="changePage('requisition')">
      <slot name="panel-title">Labs</slot>
    </requisition-panel-header>
    <patient-chart-panel-body 
      :isPageChosen="getIsPageChosen" 
      :isLoading="isLoading"
    >
      <keep-alive>
        <requisition-history-page
          data-test="requisition-history-page"
          v-if="getIsHistoryOpen"
          :requisitionList="requisitionList"
          @close="changePage"
          @fetchFilledPdfData="payload => $emit('fetchFilledPdfData', payload)"
        />
      </keep-alive>
      <keep-alive>
        <new-requisition-form
          data-test="new-requisition-form"
          v-if="getIsNewRequisitionFormOpen" 
          :selectedPdfFormTemplateId="selectedPdfFormTemplateId"
          :selectedEmployeeId="selectedEmployeeId"
          :selectedClinicId="selectedClinicId"
          :employeeOptions="employeeOptions"
          :clinicOptions="clinicOptions"
          :pdfFormTemplateOptions="pdfFormTemplateOptions"
          @selectClinic="payload => $emit('selectClinic', payload)"
          @selectEmployee="payload => $emit('selectEmployee', payload)"
          @selectPdfFormTemplate="payload => $emit('selectPdfFormTemplate', payload)"
          @newRequisition="$emit('newRequisition')"
          @close="changePage"
        />
      </keep-alive>
    </patient-chart-panel-body>
    <slot name="panel-paginated-results" />
  </patient-chart-panel-container>
</template>

<script lang="ts">
import { defineComponent, defineAsyncComponent, PropType, watchEffect, ref, computed } from 'vue';
import { BaseDropDownOptionItem } from '@/ui-library/base-drop-down/types/base-drop-down-option-item.interface';
import PatientChartPanelBody from '@/features/patient-chart/components/panel/patient-chart-panel-body.vue';
import PatientChartPanelContainer from '@/features/patient-chart/components/panel/patient-chart-panel-container.vue';
import { RequisitionListItem } from '@/features/patient-chart/features/requisitions/types/requisition-list-item.interface';
import RequisitionPanelHeader from './requisition-panel-header.vue';
import { RequisitionPanelPage } from '../types/requisition-panel-page';

export default defineComponent({
  emits: [
    'fetchFilledPdfData',
    'close', 
    'newRequisition', 
    'selectPdfFormTemplate', 
    'selectEmployee', 
    'selectClinic',
    'fetchRequisitionList',
    'fetchNewRequisitionFormOptions'
  ],
  components: {
    RequisitionPanelHeader,
    PatientChartPanelBody,
    PatientChartPanelContainer,
    RequisitionHistoryPage: defineAsyncComponent(() =>
      import('./requisition-history-page.vue'),
    ),
    NewRequisitionForm: defineAsyncComponent(() =>
      import('./new-requisition-form.vue'),
    )
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    pdfFormTemplateOptions: {
      type: Array as PropType<BaseDropDownOptionItem[]>
    },
    clinicOptions: {
      type: Array as PropType<BaseDropDownOptionItem[]>
    },
    employeeOptions: {
      type: Array as PropType<BaseDropDownOptionItem[]>
    },
    selectedClinicId: {
      type: Number as PropType<BaseDropDownOptionItem['id']>
    },
    selectedEmployeeId: {
      type: Number as PropType<BaseDropDownOptionItem['id']>
    },
    selectedPdfFormTemplateId: {
      type: Number as PropType<BaseDropDownOptionItem['id']>
    },
    requisitionList: {
      type: Array as PropType<RequisitionListItem[]>
    }
  },
  setup(_, context) {

    const page = ref<RequisitionPanelPage>(null);
    const getIsHistoryOpen = computed(() => page.value === 'history');
    const getIsNewRequisitionFormOpen = computed(() => page.value === 'requisition');
    const getIsPageChosen = computed(() => page.value !== null);
    const changePage = (requestedPage: RequisitionPanelPage) => {
      page.value = requestedPage && (page.value !== requestedPage)
        ? requestedPage
        : null
    }

    watchEffect(() => {
      if (getIsHistoryOpen.value) {
        context.emit('fetchRequisitionList')
      }
    })
    watchEffect(() => {
      if (getIsNewRequisitionFormOpen.value) {
        context.emit('fetchNewRequisitionFormOptions')
      }
    })

    return {
      getIsPageChosen,
      getIsHistoryOpen,
      getIsNewRequisitionFormOpen,
      changePage
    };
  }
});
</script>