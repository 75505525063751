<template>
  <video-link 
    v-if="patientChartStore.getShowVideoLink"
    :patientId="patientId"
    :email="patientEmail"
    :phone="patientPhone"
    :hostName="currentEmployeeName"    
  />
  <requisition-modal />
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent, onMounted, onUnmounted } from 'vue';
import { usePatientChartStore } from './store/use-patient-chart-store';
import RequisitionModal from './features/requisitions/features/requisition-modal/requisition-modal.vue';

export default defineComponent({
  components: { 
    VideoLink: defineAsyncComponent(() =>
      import('./features/video-link/video-link.vue')
    ),
    RequisitionModal
  },
  props: {
    patientId: {
      type: Number,
      required: true
    },
    patientEmail: {
      type: String,
      required: true
    },
    patientPhone: {
      type: String,
      required: true
    },
    currentEmployeeName: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const patientChartStore = usePatientChartStore();
    onMounted(() => patientChartStore.setPatientId(props.patientId));
    onUnmounted(() => patientChartStore.$reset());

    return {
      patientChartStore
    }
  }
});
</script>