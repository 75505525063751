<template>
  <div
    :class="tooltipClasses" 
    :data-tooltip-content="`#${uniqTooltipId}`"
  >
    <slot />
    <base-tooltip-content :contentId="uniqTooltipId">
      <slot name="tooltip-content">
        {{ description }}
      </slot>
    </base-tooltip-content>
  </div>
</template>

<script lang="ts">
import './tooltipster.bundle.js';
import { defineComponent, onMounted } from 'vue';
import BaseTooltipContent from './base-tooltip-content.vue';

export default defineComponent({
  props: {
    description: {
      type: String,
      required: false
    }
  },
  components: {
    BaseTooltipContent
  },
  setup(props, context) {
    const uniqTooltipId = Math.random().toString(36).substr(2, 5);
    const tooltipClasses = { [`${uniqTooltipId}`]: true };
    onMounted(() => {
      // @ts-ignore
      $(`.${uniqTooltipId}`).tooltipster({
        theme: 'tooltipster-noir'
      });
    })
    return {
      uniqTooltipId,
      tooltipClasses
    };
  }
});
</script>