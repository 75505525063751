import { defineStore } from 'pinia';
import http from '@/http/http';
import { getFaxRequisitionFormData } from '../services/requisition-fax-service';
import { RequisitionFaxFormState } from './types/requisition-fax-form-state.interface';

export const useRequisitionFaxFormStore = defineStore('requisitionFaxFormStore', {
  state: (): RequisitionFaxFormState => ({
    searchConsultantsUrl: '',
    onlyFirstPage: false,
    label: '',
    faxNumber: '',
  }),
  actions: {
    setLabel(label) {
      this.label = label;
    },
    setFaxNumber(faxNumber) {
      this.faxNumber = faxNumber;
    },
    async setFaxForm(pdfFormId?: number) {
      try {
        if (!pdfFormId) {
          throw Error('pdfFormId does not exist when attempting to setFaxForm');
        }
        const response = await getFaxRequisitionFormData(pdfFormId);
        this.label = response.data.label;
        this.faxNumber = response.data.faxNumber;
        this.onlyFirstPage = response.data.onlyFirstPage;
        this.searchConsultantsUrl = response.data.searchConsultantsUrl;
      } catch (e) {
        console.error(e);
      }
    },
    searchConsultants() {
      if (this.searchConsultantsUrl) {
        http.callGetXmlPayload(this.searchConsultantsUrl);
      }
    },
  },
});
