<template>
  <base-drop-down-menu align="right">
    <template #button="{ toggleShowMenu }">
      <base-button-icon @click.stop="toggleShowMenu">
        <i class="fa fa-ellipsis-v" />
      </base-button-icon>
    </template>
    <template #list>
      <base-list-item v-if="showPublishButton" @click="$emit('publish')" outlined>
        <base-typography warning capitalize class="listed-button"> 
          <span>{{ isPublished ? 'unpublish' : 'publish' }}</span> 
          <i class="fa fa-book" />
        </base-typography>
      </base-list-item>
      <base-list-item @click="$emit('delete')" outlined>
        <base-typography warning capitalize class="listed-button"> 
          <span>delete</span> 
          <i class="fa fa-trash" />
        </base-typography>
      </base-list-item>
    </template>
  </base-drop-down-menu>
</template>

<script setup lang="ts">
import BaseTypography from '@/ui-library/base-typography/base-typography.vue';
import BaseButtonIcon from '@/ui-library/base-button/base-button-icon.vue';
import BaseDropDownMenu from '@/ui-library/base-drop-down-menu/base-drop-down-menu.vue';
import BaseListItem from '@/ui-library/base-list-item/base-list-item.vue';

defineEmits(['delete']);
defineProps({
  showPublishButton: {
    type: Boolean,
    default: false
  },
  isPublished: {
    type: Boolean,
    default: false
  }
});

</script>

<style lang="scss" scoped>
@import '@/styles/global-styles.scss';
.listed-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1em;
}
</style>