import { computed } from 'vue';
import sizes from '../styles/global-breakpoints';
import { useResizeWindowHeightAndWidth } from './use-resize-window-height-and-width';

export function useMediaQuery() {
  const { innerWidth } = useResizeWindowHeightAndWidth();
  // Breakpoints are expected to be number of pixels: i.e. 100px
  const getPixelCount = (breakpoint: string = '0px'): number =>
    parseInt(breakpoint.replace('px', ''));

  const smallWidthPixels = computed((): number =>
    getPixelCount(sizes.smBreakpoint)
  );
  const mediumWidthPixels = computed((): number =>
    getPixelCount(sizes.mdBreakpoint)
  );
  const largeWidthPixels = computed((): number =>
    getPixelCount(sizes.lgBreakpoint)
  );
  const extraLargeWidthPixels = computed((): number =>
    getPixelCount(sizes.xlBreakpoint)
  );

  const isSmallWidth = computed(
    (): boolean => innerWidth.value < smallWidthPixels.value
  );
  const isSmallerThanMediumWidth = computed(
    (): boolean => innerWidth.value < mediumWidthPixels.value
  );
  const isSmallerThanLargeWidth = computed(
    (): boolean => innerWidth.value < largeWidthPixels.value
  );
  const isSmallerThanExtraLargeWidth = computed(
    (): boolean => innerWidth.value < extraLargeWidthPixels.value
  );

  return {
    isSmallWidth,
    isSmallerThanMediumWidth,
    isSmallerThanLargeWidth,
    isSmallerThanExtraLargeWidth
  };
}
