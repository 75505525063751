<template>
  <div class="requisition-buttons-bar">
    <requisition-toolbar-button 
      data-test="attachments-button"
      v-if="attachmentsUrl" 
      tooltipDescription="Attachments"
      @click="$emit('openAttachmentsMenu')"
    >
      <i class="fa fa-paperclip fa-lg" />
    </requisition-toolbar-button>
    <requisition-toolbar-button 
      data-test="outgoing-faxes-button"
      @mouseenter="$emit('hoverOutgoingFaxes')"
    >
      <outgoing-faxes-icon :faxStatus="faxStatus" />
      <template #tooltip-content>
        <outgoing-faxes-tootlp-content :outgoingFaxRecipients="outgoingFaxRecipients" />
      </template>
    </requisition-toolbar-button>
    <requisition-toolbar-button 
      data-test="fax-menu-button"
      @click="$emit('openFaxMenu')" 
      tooltipDescription="Fax requisition"
    >
      <i class="fa fa-fax fa-lg" />
    </requisition-toolbar-button>
    <requisition-toolbar-button 
      data-test="release-to-connect-button"
      v-if="releaseToConnectUrl"
      :tooltipDescription="releaseToPatientTooltipDescription"
      @click="$emit('releaseToPatient')"
    >
      <send-to-connect-icon :isReleasedToPatient="isReleasedToPatient" />
    </requisition-toolbar-button>
    <requisition-toolbar-button
      data-test="print-button"
      v-if="printUrl" 
      tooltipDescription="Print"
      @click="$emit('printRequisition')"
    > 
      <i class="fa fa-print fa-lg" />
    </requisition-toolbar-button>
    <requisition-toolbar-button 
      v-if="deleteUrl" 
      data-test="delete-button"
      tooltipDescription="Delete"
      @click="$emit('deleteRequisition')"
    > 
      <i class="fa fa-trash fa-lg" />
    </requisition-toolbar-button>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { FaxStatus } from '@/types/fax-status.type';
import { OutgoingFaxRecipient } from '../../types/outgoing-fax-recipient.interface';
import OutgoingFaxesIcon from './outgoing-faxes-icon.vue';
import OutgoingFaxesTootlpContent from './outgoing-faxes-tootlp-content.vue';
import RequisitionToolbarButton from './requisition-toolbar-button.vue';
import SendToConnectIcon from './send-to-connect-icon.vue';

export default defineComponent({
  emits: [
    'openAttachmentsMenu', 
    'openFaxMenu',
    'hoverOutgoingFaxes', 
    'releaseToPatient', 
    'printRequisition',
    'deleteRequisition',
  ],
  components: { 
    RequisitionToolbarButton, 
    OutgoingFaxesIcon, 
    SendToConnectIcon,
    OutgoingFaxesTootlpContent 
  },
  props: {
    outgoingFaxRecipients: {
      type: Array as PropType<OutgoingFaxRecipient[]>,
      default: () => []
    },
    isReleasedToPatient: {
      type: Boolean,
      default: true
    },
    attachmentsUrl: {
      type: String,
      required: false
    },
    releaseToConnectUrl: {
      type: String,
      required: false
    },
    printUrl: {
      type: String,
      required: false
    },
    deleteUrl: {
      type: String,
      required: false
    },
    faxStatus: {
      type: String as PropType<FaxStatus>,
      required: false,
      default: null
    }
  },
  setup(props) {
    const releaseToPatientTooltipDescription = computed(() => 
      props.isReleasedToPatient
        ? 'Recall from Connect'
        : 'Release to Connect'
    );
    return {
      releaseToPatientTooltipDescription
    };
  }
});
</script>

<style lang="scss" scoped>
.requisition-buttons-bar {
  display: flex;
  gap: 3em;
  padding-right: 1em;
}
</style>