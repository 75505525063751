import { ref, Ref } from 'vue';

export function useToggledValue(
  options?: UseToggledValueOptions
): UseToggledValueResult {
  const isTrue = ref(Boolean(options?.defaultValue));
  const toggleValue = (value?: boolean) => {
    if (value === false) {
      isTrue.value = false;
    } else if (value === true) {
      isTrue.value = true;
    } else {
      isTrue.value = !isTrue.value;
    }
  };
  return {
    isTrue,
    toggleValue
  };
}

export interface UseToggledValueResult {
  isTrue: Ref<boolean>;
  toggleValue: (value?: boolean) => void;
}

export interface UseToggledValueOptions {
  defaultValue: boolean;
}
