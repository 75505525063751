<template>
  <div class="requisition-fax-menu-container"> 
    <div class="requisition-fax-menu">
      <base-label description="name" inline>
        <base-input data-test="fax-label" v-model="label" />
      </base-label>
      <base-label description="fax #" inline>
        <base-input data-test="fax-number" v-model="faxNumber"/>
        <template #button>
          <base-button @click="searchConsultants">
            <i class="fa fa-search" /> 
          </base-button> 
        </template>
      </base-label>
      <first-page-only-checkbox data-test="first-page-only-checkbox" v-model="onlyFirstPage" />
      <cancel-button @click="$emit('close')" />
      <base-button data-test="send-fax-button" wide @click="createFax"> Fax </base-button>
      <div class="loader-container">
        <base-loader :isLoading="isFaxing" message="preparing fax..." />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, watchEffect } from 'vue';
import CancelButton from '@/ui-library/base-button/components/cancel-button.vue';
import BaseButton from '@/ui-library/base-button/base-button.vue';
import BaseInput from '@/ui-library/base-input/base-input.vue';
import BaseLabel from '@/ui-library/base-label/base-label.vue';
import BaseButtonIcon from '@/ui-library/base-button/base-button-icon.vue';
import BaseTypography from '@/ui-library/base-typography/base-typography.vue';
import FirstPageOnlyCheckbox from './components/first-page-only-checkbox.vue';
import { useRequisitionFaxFormStore } from './store/use-requisition-fax-form-store';
import { storeToRefs } from 'pinia';
import BaseLoader from '@/ui-library/base-loader/base-loader.vue';
import { FaxRequisitionRequestPayload } from './services/types/fax-requisition-request-payload.interface';

export default defineComponent({
  emits: {
    close: null,
    sendFax: (payload: FaxRequisitionRequestPayload) => Boolean(
      payload.onlyFirstPage !== undefined 
        && payload.label 
        && payload.faxNumber
        && payload.pdfFormId
      )
  },
  components: { 
    CancelButton,
    BaseButton,
    BaseInput,
    BaseLabel,
    BaseButtonIcon,
    BaseTypography,
    FirstPageOnlyCheckbox,
    BaseLoader 
  },
  props: {
    isFaxing: {
      type: Boolean,
      required: true
    },
    pdfFormId: {
      type: Number,
      required: false
    },
  },
  setup(props, context) {
    /**
     *  useRequisitionFaxFormStore connects rails and vue using the Global State Facade
     *  On selection of consultant from rails app we update label and faxNumber using
     *  the Global State Facade which updates the label/faxNumber on useRequisitionFaxFormStore
     *  See: 
     * app/views/consultants/select.js.erb
     * app/views/consultants/select.js+phone.erb
     * app/javascript/src/plugins/types/global-state-facade.functions.ts
     */
    const store = useRequisitionFaxFormStore();
    const {
      onlyFirstPage,
      label,
      faxNumber
    } = storeToRefs(store);

    watchEffect(async () => {
      if (props.pdfFormId) {
        store.setFaxForm(props.pdfFormId);
      }
    });
    
    const createFax = () => {
      if (!props.pdfFormId) {
        throw Error('pdfFormId is not defined when trying to fax');
      }
      context.emit('sendFax', { 
        pdfFormId: props.pdfFormId,
        onlyFirstPage: onlyFirstPage.value, 
        label: label.value,
        faxNumber: faxNumber.value
      });
    }

    return {
      label,
      faxNumber,
      onlyFirstPage,
      createFax,
      searchConsultants: store.searchConsultants
    };
  }
});
</script>

<style lang="scss" scoped>
@import '@/styles/global-styles.scss';

.requisition-fax-menu-container {
  width: 100%;
  padding: 8px;
  background: #f0f0f0;
}
.requisition-fax-menu {
  display: grid;
  gap: 1em;
  grid-template-columns: auto auto auto minmax(auto, 160px) minmax(auto, 160px);
  width: 100%;
  max-width: $ava-breakpoint-lg;
  margin: auto;
  @media only screen and (max-width: $ava-breakpoint-md) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media only screen and (max-width: $ava-breakpoint-sm) {
    grid-template-columns: 1fr;
  }
  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column: 1/-1;
    gap: 1em;
  }
}
</style>
