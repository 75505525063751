<template>
  <i 
    class="send-to-connect-icon fa fa-paper-plane-o fa-lg" 
    :class="sendToConnectClasses" 
  />
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    isReleasedToPatient: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  setup(props) {
    const sendToConnectClasses = computed(() => ({
      'to-be-retrieved': props.isReleasedToPatient
    }))
    return {
      sendToConnectClasses
    };
  }
});
</script>

<style lang="scss" scoped>
.send-to-connect-icon {
  color: green;
  &.to-be-retrieved {
    color: red;
  }
}
</style>