window.initTranscriberButton = initTranscriberButton;

export function initTranscriber($) {

  window.addEventListener('vue-is-mounted', (function(e) {
    initTranscriberButton();
  }), false);

}

function initTranscriberButton() {
  console.log('transcriber module loaded!')
  $(document).off('click', '#toggle-transcription-button');
  $(document).on('click', '#toggle-transcription-button', function() {
    $('#transcriber-container').slideToggle();
    $('#transcriber-button').toggleClass('footer-button-active');
  });
}
