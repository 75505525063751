import { defineStore } from 'pinia'
import http from '@/http/http';
import { leadingThrottler } from '@/utils/utility-helpers';
// import { WebViewerInstance } from '@pdftron-8.1.0/webviewer';
// import { exportAnnotationsFromWebViewer } from '@/components/pdf-viewer/utils/pdf-viewer.functions';
import { getRequistionData } from '../../../services/requisition-service';
import requisitionToolbarService, { saveRequisitionAnnotations } from '../services/requisition-services';
import { FaxRequisitionRequestPayload } from '../features/requisition-fax-form/services/types/fax-requisition-request-payload.interface';
import { createRequisitionFax } from '../features/requisition-fax-form/services/requisition-fax-service';
import { RequisitionModalState } from './types/use-requisition-modal-state';

export const useRequisitionModalStore = defineStore('requisitionModalStore', {
  state: (): RequisitionModalState => ({
    filledPdfId: null,
    isFaxMenuOpen: false,
    isLoading: false,
    isFaxing: false,
    isSaving: false,
    attachmentsUrl: '',
    releaseToConnectUrl: '',
    printUrl: '',
    deleteUrl: '',
    faxInfomationUrl: '',
    faxStatus: null,
    isReleasedToPatient: false,
    outgoingFaxRecipients: [],
    xfdfString: '',
    pdfFormTemplateId: null,
  }),
  getters: {
    getIsFaxMenuOpen: (state) => state.isFaxMenuOpen,
    getIsLoading: (state) => state.isLoading,
    getFaxStatus: (state) => state.faxStatus,
    getIsReleasedToPatient: (state) => state.isReleasedToPatient,
    getOutgoingFaxRecipients: (state) => state.outgoingFaxRecipients,
    getXfdfString: (state) => state.xfdfString,
    getPdfFormTemplateId: (state) => state.pdfFormTemplateId,
    getFilledPdfId: (state) => state.filledPdfId
  },
  actions: {
    resetRequisitionData() {
      this.filledPdfId = null;
      this.attachmentsUrl = '';
      this.releaseToConnectUrl = '';
      this.printUrl = '';
      this.deleteUrl = '';
      this.faxInfomationUrl = '';
      this.faxStatus = null;
      this.isReleasedToPatient = false;
      this.outgoingFaxRecipients = [];
      this.xfdfString = '';
      this.pdfFormTemplateId = null;
    },
    async saveRequisition(pdfFormId?: number | null, pdfViewerInstance?: any) {
      if (!pdfViewerInstance) {
        throw Error('Pdftron WebViewerInstance is not instantiated');
      }
      if (!pdfFormId) {
        throw Error('Saving requisition requires pdfFormId');
      }
      try {
        this.setIsSaving(true);
        await saveRequisitionAnnotations(
          pdfFormId,
          // await exportAnnotationsFromWebViewer(pdfViewerInstance)
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.setIsSaving(false);
      }
    },
    setIsSaving(status: boolean) {
      this.isSaving = status;
    },
    toggleIsReleasedToPatient() {
      this.isReleasedToPatient = !this.isReleasedToPatient;
    },
    async initializeAttachments() {
      requisitionToolbarService.initiateAttachmentsFeature(this.attachmentsUrl);
    },
    async releaseToPatient() {
      const response =
        await requisitionToolbarService.updateReleaseToConnectStatus(
          this.releaseToConnectUrl
        );
      if (http.isResponseSuccessful(response)) {
        this.toggleIsReleasedToPatient();
      }
    },
    async getRequisitionData(filledPdfId: number) {
      try {        
        this.isLoading = true;
        const response = await getRequistionData(filledPdfId);
        this.$patch({
          filledPdfId: filledPdfId,
          attachmentsUrl: response.data.attachmentsUrl,
          releaseToConnectUrl: response.data.releaseToPatientUrl,
          isReleasedToPatient: response.data.isReleasedToPatient,
          printUrl: response.data.printUrl,
          deleteUrl: response.data.deleteUrl,
          faxStatus: response.data.faxStatus,
          faxInfomationUrl: response.data.faxInfomationUrl,
          pdfFormTemplateId: response.data.pdfFormTemplateId,
          xfdfString: response.data.xfdfData
        })
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async deleteRequisition(): Promise<boolean> {
      if (confirm('Are you sure you want to delete this requisition?')) {
        await requisitionToolbarService.deleteRequisition(this.deleteUrl);
        return true
      } else {
        return false
      }
    },
    openRequisitionInNewTab() {
      window.open(this.printUrl, '_blank');
    },
    async fetchFaxInfo() {
      if (this.faxInfomationUrl) {
        const response = await requisitionToolbarService.getFaxInformationUrl(
          this.faxInfomationUrl
        );
        this.outgoingFaxRecipients = response.data;
      }
    },
    createThrottledFetchFaxInfo() {
      return leadingThrottler(this.fetchFaxInfo, 5000);
    },
    async sendFax(payload: FaxRequisitionRequestPayload) {
      try {
        this.isFaxing = true;
        await createRequisitionFax(payload);
      } catch (error) {
        console.error(error);
      } finally {
        this.isFaxing = false;
      }
    },
    toggleIsFaxMenuOpen() {
      this.isFaxMenuOpen = !this.isFaxMenuOpen
    }
  },
});