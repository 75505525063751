<template>
  <div class="edit-form-template-title-bar">
    <base-typography headline class="edit-form-template-title">
      <slot />
    </base-typography>
    <form-editor-card-header-options
      :showPublishButton="showPublishButton"
      :isPublished="isPublished"
      @delete="$emit('deleteTemplate')" 
      @publish="$emit('publishTemplate')" 
    />
  </div>
</template>

<script setup lang="ts">
import BaseTypography from '@/ui-library/base-typography/base-typography.vue';
import FormEditorCardHeaderOptions from './form-editor-card-header-options.vue';

defineEmits(['deleteTemplate', 'publishTemplate']);
withDefaults(defineProps<{ 
  isPublished: boolean;
  showPublishButton: boolean;
}>(), {
  isPublished: false,
  showPublishButton: false
})

</script>

<style lang="scss" scoped>
.edit-form-template-title-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 1em;
  .edit-form-template-title {
    flex-grow: 1;
  }
}
</style>