<template>
  <teleport to="#teleport-anchor-modal">
    <div v-bind="$attrs" class="modal-background">
      <slot />
    </div>
  </teleport>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  inheritAttrs: false,
  setup() {
    return {}
  }
});
</script>

<style lang="scss" scoped>
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  background-color:rgba(0,0,0, 0.2);
  height: 100vh;
  width: 100vw;
}
</style>