<template>
  <base-modal-background @click="$emit('close')">
    <base-modal-window @close="$emit('close')" />
  </base-modal-background>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import BaseButtonIcon from '../base-button/base-button-icon.vue';
import BaseModalBackground from './base-modal-background.vue';
import { useMediaQuery } from '@/composition/use-media-query';
import BaseModalWindow from './base-modal-window.vue';

export default defineComponent({
  components: { BaseModalBackground, BaseButtonIcon, BaseModalWindow },
  emits: ['close'],
  setup() {
    const { isSmallerThanMediumWidth } = useMediaQuery();
    return {
      isSmallerThanMediumWidth
    };
  }
});
</script>