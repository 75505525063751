import { defineStore } from 'pinia';
import { BaseDropDownOptionItem } from '@/ui-library/base-drop-down/types/base-drop-down-option-item.interface';
import { getDataToPrepareRequisitionForm } from '../../services/requisition-service';
import { FilledPdfCategory } from '../../types/filled-pdf-category.type';
import { NewRequisitionState } from '../../types/new-requisition-state.interface';

export const createNewRequisitionStore = (
  storeLabel: string, 
  filledPdfCategory: FilledPdfCategory
) => {
  return defineStore(storeLabel, {
    state: (): NewRequisitionState => ({
      selectedClinicId: null,
      selectedEmployeeId: null,
      selectedPdfFormTemplateId: null,
      isLoadingFormOptions: false,
      clinics: [],
      employees: [],
      pdfFormTemplates: []
    }),
    getters: {
      getSelectedClinicId: (state) => state.selectedClinicId,
      getSelectedEmployeeId: (state) => state.selectedEmployeeId,
      getSelectedPdfFormTemplateId: (state) => state.selectedPdfFormTemplateId,
      getIsLoadingFormOptions: (state) => state.isLoadingFormOptions,
      getClinicOptions: (state) => state.clinics,
      getEmployeeOptions: (state) => state.employees,
      getPdfFormTemplateOptions: (state) => state.pdfFormTemplates,
      getIsNewRequisitionFormFilled: (state) => Boolean( 
        state.selectedClinicId && 
        state.selectedEmployeeId && 
        state.selectedPdfFormTemplateId
      )
    },
    actions: {
      async fetchNewRequisitionFormOptions() {
        try {
          this.isLoadingFormOptions = true;
          const response = await getDataToPrepareRequisitionForm(filledPdfCategory);
          this.clinics = response.data.clinics;
          this.employees = response.data.employees;
          this.pdfFormTemplates = response.data.pdfFormTemplates;
        } catch (error) {
          console.error(error);
        } finally {
          this.isLoadingFormOptions = false;
        }
      },
      selectFormItem(type: 'clinic' | 'employee' | 'pdfFormTemplates') {
        return (id: BaseDropDownOptionItem['id']) => {
          switch (type) {
            case 'clinic':
              this.selectedClinicId = id;
              break;
            case 'employee':
              this.selectedEmployeeId = id;
              break;
            case 'pdfFormTemplates':
              this.selectedPdfFormTemplateId = id;
              break;
            default:
              return;
          }
        }
      }
    }
  });
} 
