import { isThisQuarter } from 'date-fns';
import { defineStore } from 'pinia';
import { getFilledPdfs } from '../../services/requisition-service';
import { FilledPdfCategory } from '../../types/filled-pdf-category.type';
import { RequisitionHistoryState } from '../../types/requisition-history-state.interface';

export function createRequisitionHistoryStore(
  storeName: string,
  requisitionType: FilledPdfCategory
) {
  return defineStore(storeName, {
    state: (): RequisitionHistoryState => ({
      requisitionList: [],
      isLoadingRequisitionList: false,
    }),
    getters: {
      getIsLoadingRequisitionList: (state) => state.isLoadingRequisitionList,
      getRequisitionList: (state) => state.requisitionList,
    },
    actions: {
      async fetchRequisitionList() {
        try {
          this.isLoadingRequisitionList = true;
          const response = await getFilledPdfs(requisitionType);
          this.requisitionList = response.data;
        } catch (error) {
          console.error(error);
        } finally {
          this.isLoadingRequisitionList = false;
        }
      },
      removeRequisitionByFilledPdfId(id: number | null) {
        if (id) {
          const index = this.requisitionList.findIndex(item => id === item.filledPdfId);
          if (index > -1) {
            this.requisitionList.splice(index, 1);
          }
        }
      }
    },
  });
}
