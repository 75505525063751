// import { WebViewerInstance } from '@pdftron-8.1.0/webviewer';
import { InjectionKey, Ref } from 'vue';

const PdfViewer: InjectionKey<Ref<any>> = Symbol(
  'PatientChart_RequisitionsModal_PdfViewer'
);
const SetPdfViewer: InjectionKey<(i: any) => void> = Symbol(
  'PatientChart_RequisitionsModal_SetPdfViewer'
);

export default {
  PdfViewer,
  SetPdfViewer
}