<template>
  <div class="form-editor-card-header">
    <slot>  
      <div class="title-row">
        <slot name="pre-title" />
        <base-typography v-if="title" bold>{{ title }}</base-typography>
      </div>
      <div class="icon-row">
        <i v-if="isDetailsOpen" class="fa fa-chevron-up" />
        <i v-else class="fa fa-chevron-down" />
        <form-editor-card-header-options @delete="$emit('delete')" />
      </div>
    </slot>
  </div>
</template>

<script setup lang="ts">
import BaseTypography from '@/ui-library/base-typography/base-typography.vue';
import FormEditorCardHeaderOptions from './form-editor-card-header-options.vue';

defineEmits(['delete']);

defineProps({
  title: {
    type: String,
    required: false
  },
  isDetailsOpen: {
    type: Boolean,
    default: false
  }
})

</script>

<style lang="scss" scoped>
@import '@/styles/global-styles.scss';
.form-editor-card-header {
  cursor: pointer;
  border-radius: 0.2em;
  &:hover {
    box-shadow: 0 0 0 1px $ava-affirmative-color;
  }
  padding: 1em;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title-row,
  .icon-row {
    display: flex;
    align-items: center;
    gap: 1em;
    
  }  
}
</style>