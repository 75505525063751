<template>
  <div class="custom-form-template-layout">
    <div class="ava-col-12">
      <base-button @click="$emit('showCreateMenu')"> back to menu </base-button>
    </div>
    <div class="edit-preview-row">
      <div class="ava-col-6">
        <slot name="edit-form" />
      </div>
      <div class="ava-col-6">
        <slot name="preview-form" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseButton from '@/ui-library/base-button/base-button.vue';

defineEmits(['showCreateMenu']);

</script>

<style lang="scss" scoped>
.custom-form-template-layout {
  display: flex;
  gap: 1em;
  flex-direction: column;
  .edit-preview-row {
    display: flex;
    gap: 1em;
  }
}
</style>