<template>
  <div class="patient-chart-panel-container">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>

<style lang="scss" scoped>
.patient-chart-panel-container {
  box-shadow: 0 3px 4px 0 rgb(0 0 0 / 10%);
  min-height: 6em;
}
</style>