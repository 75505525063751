<template>
  <div class="outgoing-faxes-tooltip-content">
    <base-typography title> Outgoing Faxes </base-typography>
    <hr />
    <base-typography v-if="!isSomeFaxes">
      There are no outgoing faxes
    </base-typography>
    <div 
      v-for="recipient in outgoingFaxRecipients" 
      :key="recipient.faxNumber"
      class="recipient"
    > 
      <base-typography bold>
        {{recipient.name}} 
        ({{formatPhone(recipient.faxNumber)}})
      </base-typography>
      
      <div 
        v-for="fax in recipient.faxes" 
        :key="fax.createdAt" 
        class="fax"
      >
        <base-typography> {{ formatDateFromString(fax.createdAt) }} </base-typography>
        <base-typography> {{ fax.employeeLastName }} </base-typography>
        <base-typography> {{ fax.status }} </base-typography>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import BaseTypography from '@/ui-library/base-typography/base-typography.vue';
import { formatPhone } from '@/utils/phone-helpers';
import { formatDateFromString } from '@/utils/date-helpers';
import { OutgoingFaxRecipient } from '../../types/outgoing-fax-recipient.interface';


export default defineComponent({
  components: { BaseTypography },
  props: {
    outgoingFaxRecipients: {
      type: Array as PropType<OutgoingFaxRecipient[]>,
      default: () => []
    },
  },
  setup(props) {
    const isSomeFaxes = computed((): boolean => Boolean(props.outgoingFaxRecipients.length));
    return {
      isSomeFaxes,
      formatDateFromString,
      formatPhone
    };
  }
});
</script>

<style lang="scss" scoped>
.outgoing-faxes-tooltip-content {
  display: grid;
  gap: 1em;
  hr {
    margin: 0px;
  }
  .recipient {
    .fax {
      display: flex;
      justify-content: space-between;
      gap: 1em;
    }
  }
}
</style>