
// @ts-nocheck
/**
 * These scripts need to run as we transition all coffeescripts and jquery to vue
 * This wont be necessary as we complete the transition
 * 
 * NOTICE: 
 * If removed we will not have dropdowns appear when hovering over buttons in header
 */
export function transitionToVueInitializers() {
  window.$(document).foundation();
  const vueIsMountedEvent = new CustomEvent("vue-is-mounted", {
    bubbles: true
  });
  window.dispatchEvent(vueIsMountedEvent);
} 