import { createApp } from 'vue';
import App from './base-vue-app';
import { mountBaseVueInstance } from './utils/init-vue-helpers';

// Initializes root vue app
export function initVue($: any) {
  ('use strict');
  
  $(function () {
    const app = createApp(App);
    mountBaseVueInstance(app, 'base-vue-app');
  });
}

// Initializes application-announcements feature
import '@/features/application-announcements/initialize';
import '@/features/custom-form-templates/initialize';