import { defineStore } from 'pinia';
import { useRequisitionModalStore } from '../features/requisition-modal/store/use-requisition-modal-store';
import { createFilledPdfForm } from '../services/requisition-service';
import { CreateFilledPdfFormRequestProps } from '../types/create-filled-pdf-form-request-props.interface';
import { ViewRequisitionState } from '../types/view-requisition-state.interface';

export const useViewRequisitionStore = defineStore('viewRequisitionStore', {
  state: (): ViewRequisitionState => ({
    isLoading: false,
    pdfFormIdToView: null,
  }),
  getters: {
    getPdfFormIdToView: (state) => state.pdfFormIdToView,
    getIsLoading: (state) => Boolean(state.isLoading),
    getIsRequisitionModalOpen: (state) => Boolean(state.pdfFormIdToView),
  },
  actions: {
    closeRequisitionModal() {
      const requisitionModalStore = useRequisitionModalStore();
      requisitionModalStore.resetRequisitionData();
      this.pdfFormIdToView = null;
      this.isLoading = false;
    },
    async initializeNewRequisitionForm(
      payload: CreateFilledPdfFormRequestProps,
      isPayloadValid: boolean
    ) {
      if (isPayloadValid) {
        try {
          this.isLoading = true;
          const response = await createFilledPdfForm(payload);
          this.viewPdfForm(response.data.id)
        } catch (error) {
          console.error(error);
        } finally {
          this.isLoading = false;
        }
      }
    },
    viewPdfForm(payload: number) {
      // pdfFormIdToView is sent down to requsition-modal as a prop
      // pdfFormId prop is watched and fetches pdf data when it changes
      this.pdfFormIdToView = payload;
    },
  },
});