<template>
  <requisition-panel-menu 
    :requisitionList="requisitionList" 
    :selectedPdfFormTemplateId="selectedPdfFormTemplateId"
    :selectedEmployeeId="selectedEmployeeId"
    :selectedClinicId="selectedClinicId"
    :employeeOptions="employeeOptions"
    :clinicOptions="clinicOptions"
    :pdfFormTemplateOptions="pdfFormTemplateOptions"
    :isLoading="isLoading"
    @fetchFilledPdfData="fetchFilledPdfData"
    @selectClinic="selectClinic"
    @selectEmployee="selectEmployee"
    @selectPdfFormTemplate="selectPdfFormTemplate"
    @newRequisition="initializeNewRequisitionForm"
    @fetchRequisitionList="fetchRequisitionList"
    @fetchNewRequisitionFormOptions="fetchNewRequisitionFormOptions"
  >
    <template #panel-title>
      <slot name="panel-title" />
    </template> 
    <template #panel-paginated-results>
      <slot name="panel-paginated-results" />
    </template> 
  </requisition-panel-menu>
</template>

<script lang="ts">
import { defineComponent, defineAsyncComponent } from 'vue';
import { useViewRequisitionStore } from '../../store/use-view-requisition-store';
import RequisitionPanelMenu from '../../components/requisition-panel-menu.vue';
import { useRequisitionPanelManager } from '../../composition/use-requisition-panel-manager';
import { useInvestigationsNewRequisitionFormStore } from './store/use-investigations-panel-new-requisition-form-store';
import { useInvestigationsPanelRequisitionHistoryStore } from './store/use-investigations-panel-requisition-history-store';

export default defineComponent({
  components: {
    RequisitionPanelMenu,
    RequisitionModal: defineAsyncComponent(() =>
      import('../requisition-modal/requisition-modal.vue'),
    )
  },
  setup() {
    const newRequisitionFormStore = useInvestigationsNewRequisitionFormStore();
    const requisitionHistoryStore = useInvestigationsPanelRequisitionHistoryStore();
    const viewRequisitionStore = useViewRequisitionStore();

    const { 
      isLoading,
      requisitionList,
      pdfFormIdToView,
      isRequisitionModalOpen,
      clinicOptions,
      employeeOptions,
      pdfFormTemplateOptions,
      selectedClinicId,
      selectedEmployeeId,
      selectedPdfFormTemplateId,
      initializeNewRequisitionForm,
      closeRequisitionModal,
      fetchFilledPdfData,
      fetchRequisitionList,
      selectClinic,
      selectEmployee,
      selectPdfFormTemplate,
      fetchNewRequisitionFormOptions
    } = useRequisitionPanelManager(
      newRequisitionFormStore, 
      requisitionHistoryStore, 
      viewRequisitionStore, 
      'Investigation'
    )

    return {
      isLoading,
      requisitionList,
      pdfFormIdToView,
      isRequisitionModalOpen,
      clinicOptions,
      employeeOptions,
      pdfFormTemplateOptions,
      selectedClinicId,
      selectedEmployeeId,
      selectedPdfFormTemplateId,
      initializeNewRequisitionForm,
      closeRequisitionModal,
      fetchFilledPdfData,
      fetchRequisitionList,
      selectClinic,
      selectEmployee,
      selectPdfFormTemplate,
      fetchNewRequisitionFormOptions
    };
  }
});
</script>