import { usePatientChartStore } from '../../../store/use-patient-chart-store';

const createBasePatientChartUrl = () => {
  const patientChartStore = usePatientChartStore();
  return `/patient_view/patients/${patientChartStore.getPatientId}`;
};

export const createEndpointForPatientRequisitions = () =>
  `${createBasePatientChartUrl()}/filled_pdf_forms`;

// ie. localhost:3000/patient_view/patients/1/filled_pdf_forms/new
export const createEndpointForNewPatientRequisition = () =>
  `${createEndpointForPatientRequisitions()}/new`;

// ie. localhost:3000/patient_view/patients/1/filled_pdf_forms/1/edit
export const createEndpointForEditingPatientRequisition = (
  filledPdfId: number
) => `${createEndpointForPatientRequisitions()}/${filledPdfId}/edit`;
