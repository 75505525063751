window.initializeTranscriberRecorder = initializeTranscriberRecorder;

function initializeTranscriberRecorder() {
  const startButton = document.querySelector('#start-recording');
  const stopButton = document.querySelector('#stop-recording');
  const transcriptionSgid = document.querySelector('#transcription-sgid').getAttribute('data-transcription-sgid');
  const finalizeButton = document.querySelector('#finalize-transcription-button');
  const footerButton = document.querySelector('#toggle-transcription-button');

  let mediaRecorder = null;
  let stream = null;
  let chunks = [];
  let timer = new Timer();
  let startTime = 0;
  let stopTime = 0;

  const startRecording = async () => {
    try {
      window.onbeforeunload = function(){
        return 'There is an Ava Scribe in progress - if you leave it will be lost. Are you sure you want to leave?';
      };
      stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorder = new MediaRecorder(stream);
      timer.start();
  
      mediaRecorder.addEventListener('dataavailable', (e) => {
        if (e.data.size > 0) {
          chunks.push(e.data);
        }
      });
  
      mediaRecorder.addEventListener('stop', async () => {
        stopTime = Date.now();
        const blob = new Blob(chunks, { type: 'audio/webm' });
        saveAudio(blob);
        chunks = [];
      });

      mediaRecorder.addEventListener('start', async () => {
        startTime = Date.now();
        chunks = [];
      });

      mediaRecorder.start();
  
      startButton.classList.add('hidden-field');
      footerButton.classList.add('instant-messenger-unread');
      stopButton.classList.remove('hidden-field');
      finalizeButton.classList.add('disabled');
      finalizeButton.disabled = true;
  
      setInterval(async () => {
        if (mediaRecorder && mediaRecorder.state === 'recording') {
          mediaRecorder.stop();
          mediaRecorder.start();
        }
      }, 0.5 * 60 * 1000); // 30 sec
    } catch (err) {
      console.error('Error while starting recording:', err);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder && mediaRecorder.state === 'recording') {
      mediaRecorder.stop();
      startButton.classList.remove('hidden-field');
      footerButton.classList.remove('instant-messenger-unread');
      stopButton.classList.add('hidden-field');
      finalizeButton.classList.remove('disabled');
      finalizeButton.disabled = false;
      window.onbeforeunload = null;
      timer.stop();
      stream.getTracks().forEach(track => track.stop());
    }
  };
  
  const saveAudio = async (blob) => {
    const formData = new FormData();
    let duration = Math.round((stopTime - startTime) / 100) / 10;
    formData.append('audio_file', blob);
    formData.append('transcription_sgid', transcriptionSgid);
    formData.append('duration', duration);
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  
    try {
      const response = await fetch('/transcriber/transcription_audio_files', {
        method: 'POST',
        body: formData,
        headers: {
          'X-CSRF-Token': csrfToken,
        },
        credentials: 'same-origin',
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error: ${response.status}`);
        window.onbeforeunload = null;
      }

      const data = await response.json();
      addTranscriptionAudioFileElement(data, duration);

    } catch (err) {
      console.error('Error while uploading audio:', err);
      window.onbeforeunload = null;
    }
  };

  startButton.addEventListener('click', startRecording);
  stopButton.addEventListener('click', stopRecording);
}

function addTranscriptionAudioFileElement(data, duration) {
  const id = data.id;

  const newSpan = document.createElement('span');
  newSpan.classList.add('transcriber-audio-file');
  newSpan.setAttribute('data-transcription-audio-file-id', id);
  newSpan.textContent = `Queued ${duration} seconds of audio for processing...`;
  $('#transcription-audio-files-index').append(newSpan);
}

class Timer {
  constructor() {
    this.startTime = 0;
    this.elapsedTime = 0;
    this.timerInterval = null;
    this.seconds = 0;
    this.minutes = 0;
    this.hours = 0;
  }

  start() {
    if (!this.timerInterval) {
      this.startTime = Date.now() - this.elapsedTime;
      this.timerInterval = setInterval(() => {
        this.elapsedTime = Date.now() - this.startTime;
        this.update();
      }, 1000);
    }
  }

  stop() {
    clearInterval(this.timerInterval);
    this.timerInterval = null;
  }

  update() {
    const diffInSeconds = Math.floor(this.elapsedTime / 1000);
    const hours = Math.floor(diffInSeconds / 3600);
    const minutes = Math.floor((diffInSeconds % 3600) / 60);
    const seconds = diffInSeconds % 60;

    const displayHours = String(hours).padStart(2, '0');
    const displayMinutes = String(minutes).padStart(2, '0');
    const displaySeconds = String(seconds).padStart(2, '0');

    let element = document.querySelector('#transcriber-timer');
    element.textContent = `${displayHours}:${displayMinutes}:${displaySeconds}`;
  }
}
