import { App, createApp, defineAsyncComponent, markRaw } from 'vue';
import { createPinia, PiniaPluginContext } from 'pinia';
import httpPlugin from '@/plugins/plugin-http';
import globalStateFacadePlugin from '@/plugins/plugin-global-state-facade';
import { MountAdditionalVueInstanceOptions } from './types/mount-additional-vue-instance-options';
import { autoFocusDirective } from '../directives/auto-focus';
import { Router } from 'vue-router';
import { router } from '../router/router';

declare module 'pinia' { export interface PiniaCustomProperties { router: Router } }
// Should use the same pinia instance across all vue instances
const pinia = createPinia();

pinia.use(({ store }: PiniaPluginContext) => {
  store.router = markRaw(router)
})

export function mountBaseVueInstance(app: App<Element>, htmlElementId: string) {
  /**
   * Order matters:
   * GlobalStateFacadePlugin depends on Pinia, which depends on http, which depends on app mounting
   */
  if (document.getElementById(htmlElementId)) {
    app.use(httpPlugin);
    app.use(pinia);
    app.use(router);
    app.use(globalStateFacadePlugin);
    app.directive('focus', autoFocusDirective);
    app.mount(`#${htmlElementId}`);
  }
}


export function mountVueInstance(app: App<Element>, htmlElementId: string) {
  if (document.getElementById(htmlElementId)) {
    app.use(router)
    app.use(httpPlugin);
    app.use(pinia);
    app.directive('focus', autoFocusDirective);
    app.mount(`#${htmlElementId}`);
  }
}

export function mountAdditionalVueInstance(
  options: MountAdditionalVueInstanceOptions
) {
  const vueMountingEventHandler = createVueMountingEventHandler(options.fn, options.htmlElementId)
  if (options.mountOnInitialPageLoad !== false) {
    window.addEventListener('vue-is-mounted', vueMountingEventHandler, { once: true });
  }
  if (options.customEventName) {
    window.addEventListener(options.customEventName, vueMountingEventHandler, { once: true });
  }
}

const createVueMountingEventHandler = (fn, htmlElementId) => () => {
  mountVueInstance(createApp(defineAsyncComponent(fn)), htmlElementId);
}