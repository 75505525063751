import throttle from 'lodash/throttle';

export const leadingThrottler = <T = any>(
  cb: () => T, 
  ms: number
): () => T => throttle(cb, ms, { 
  leading: true, 
  trailing: false 
});

export const wait = (delay) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
};