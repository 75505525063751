<template>
  <div v-if="formTemplate" class="edit-patient-form-template">
    <slot name="edit-form-header" />
    <div>
      <slot name="form-template-questions" />
    </div>
  </div>
  
  <base-typography v-else warning> Form template is missing, please refresh page </base-typography>
</template>

<script setup lang="ts">
import { PropType } from 'vue';
import BaseTypography from '@/ui-library/base-typography/base-typography.vue';
import FormEditorCardHeaderOptions from '../components/form-editor-card-header-options.vue';
import { PatientFormTemplateDetails } from '../../../../../types/patient-form-template-details';
defineEmits(['deleteTemplate', 'publishTemplate']);
defineProps({
  formTemplate: {
    type: Object as PropType<PatientFormTemplateDetails | null>,
    required: true
  }
});

</script>

<style lang="scss" scoped>
.edit-patient-form-template {
  display: flex;
  flex-direction: column;
  gap: 2em;
  .edit-form-template-title-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 1em;
  }
  .form-template-questions-container {
    display: flex;
    flex-direction: column;
    gap: 0.5em;    
  }
}
</style>