<template>
  <div @click.stop class="modal-window">
    <main>
      <slot />
    </main>
    <aside v-if="isSmallerThanMediumWidth">
      <base-button-icon @click="$emit('close')"> 
        <i class="fa fa-times-circle fa-2x" />
      </base-button-icon>
    </aside>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useMediaQuery } from '@/composition/use-media-query';
import BaseButtonIcon from '../base-button/base-button-icon.vue';

export default defineComponent({
  emits: ['close'],
  components: { BaseButtonIcon },
  setup() {
    const { isSmallerThanMediumWidth } = useMediaQuery();
    return {
      isSmallerThanMediumWidth
    };
  }
});
</script>

<style lang="scss" scoped>
@import '@/styles/global-styles.scss';

.modal-window {
  display: flex;
  padding: 1em;
  height: 100vh;
  background-color: white;
  width: 90%;
  max-width: 212.5rem;
  margin: auto;
  gap: 0.5em;
  main {
    flex-grow: 1;
  }
  @media only screen and (max-width: $ava-breakpoint-md) {
    width: 100%;
  }
}
</style>