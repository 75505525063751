import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useViewRequisitionStore } from '../store/use-view-requisition-store';
import { FilledPdfCategory } from '../types/filled-pdf-category.type';
import { UseNewRequisitionFormStore } from '../types/new-requisition-form-store.type';
import { UseRequisitionHistoryStore } from '../types/requisition-history-store.type';
import { useRequisitionViewer } from './use-requisition-viewer';

export function useRequisitionPanelManager(
  newRequisitionFormStore: UseNewRequisitionFormStore,
  requisitionHistoryStore: UseRequisitionHistoryStore,
  viewRequisitionStore: ReturnType<typeof useViewRequisitionStore>,
  filledPdfCategory: FilledPdfCategory 
) {
  const {
    getClinicOptions,
    getEmployeeOptions,
    getPdfFormTemplateOptions,
    getSelectedClinicId,
    getSelectedEmployeeId,
    getSelectedPdfFormTemplateId
  } = storeToRefs(newRequisitionFormStore);

  const {
    getIsRequisitionModalOpen,
    getPdfFormIdToView,
  } = storeToRefs(viewRequisitionStore);

  const { getRequisitionList } = storeToRefs(
    requisitionHistoryStore
  );

  const { closeRequisitionModal, initializeNewRequisitionForm } =
    useRequisitionViewer(
      newRequisitionFormStore,
      viewRequisitionStore,
      filledPdfCategory
    );

  const isLoading = computed(
    (): boolean =>
      requisitionHistoryStore.getIsLoadingRequisitionList ||
      newRequisitionFormStore.getIsLoadingFormOptions
  );

  return {
    isLoading,
    requisitionList: getRequisitionList,
    pdfFormIdToView: getPdfFormIdToView,
    isRequisitionModalOpen: getIsRequisitionModalOpen,
    clinicOptions: getClinicOptions,
    employeeOptions: getEmployeeOptions,
    pdfFormTemplateOptions: getPdfFormTemplateOptions,
    selectedClinicId: getSelectedClinicId,
    selectedEmployeeId: getSelectedEmployeeId,
    selectedPdfFormTemplateId: getSelectedPdfFormTemplateId,
    initializeNewRequisitionForm,
    closeRequisitionModal,
    fetchFilledPdfData: viewRequisitionStore.viewPdfForm,
    fetchRequisitionList: requisitionHistoryStore.fetchRequisitionList,
    selectClinic: newRequisitionFormStore.selectFormItem('clinic'),
    selectEmployee: newRequisitionFormStore.selectFormItem('employee'),
    selectPdfFormTemplate:
      newRequisitionFormStore.selectFormItem('pdfFormTemplates'),
    fetchNewRequisitionFormOptions:
      newRequisitionFormStore.fetchNewRequisitionFormOptions,
  };
}