<template>
  <teleport to="#teleport-pdftron-webviewer-modal">
    <div class="modal-background" v-show="getPdfFormIdToView" @click="closeRequisitionModal">
      <base-modal-window @close="closeRequisitionModal">
        <div class="grid-container">
          <base-loader :isLoading="getIsLoading" :fullScreen="true" />
          <requisition-toolbar
            data-test="requisition-toolbar"
            v-if="getPdfFormIdToView"
            :pdfFormId="getPdfFormIdToView"
            :isReleasedToPatient="getIsReleasedToPatient"
            :attachmentsUrl="attachmentsUrl"
            :releaseToConnectUrl="releaseToConnectUrl"
            :printUrl="printUrl"
            :deleteUrl="deleteUrl"
            :faxStatus="getFaxStatus"
            :outgoingFaxRecipients="getOutgoingFaxRecipients"
            @close="closeRequisitionModal"
            @openAttachmentsMenu="initializeAttachments"
            @openFaxMenu="toggleIsFaxMenuOpen"
            @hoverOutgoingFaxes="fetchFaxInfo"
            @releaseToPatient="releaseToPatient"
            @printRequisition="printRequisition"
            @deleteRequisition="deleteRequisition"
          />
          <base-transition-expand>
            <requisition-fax-form
              v-if="getIsFaxMenuOpen"
              :pdfFormId="getPdfFormIdToView"
              :isFaxing="isFaxing"
              @close="toggleIsFaxMenuOpen"
              @sendFax="sendFax"
            />
          </base-transition-expand>
          <!-- <requisition-pdf-viewer
            class="web-viewer"
            :isSaving="isSaving"
            :pdfFormTemplateId="getPdfFormTemplateId"
            :xfdfString="getXfdfString"
            :pdfFormIdToView="getPdfFormIdToView"
            @saveRequisition="saveRequisition"
            @printRequisition="printRequisition"
          /> -->
        </div>
      </base-modal-window>
    </div>
  </teleport>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import BaseLoader from '@/ui-library/base-loader/base-loader.vue';
import BaseModal from '@/ui-library/base-modal/base-modal.vue';
import BaseButton from '@/ui-library/base-button/base-button.vue';
import BaseTransitionExpand from '@/ui-library/base-transition/base-transition-expand.vue';
import BaseModalWindow from '@/ui-library/base-modal/base-modal-window.vue';
import RequisitionFaxForm from './features/requisition-fax-form/requisition-fax-form.vue';
// import RequisitionPdfViewer from './features/requisition-pdf-viewer/requisition-pdf-viewer.vue';
import RequisitionToolbar from './components/requisition-toolbar/requisition-toolbar.vue';
import { useRequisitionModalManager } from './composition/use-requisition-modal-manager';

export default defineComponent({
  components: {
    BaseLoader,
    BaseModal,
    BaseButton,
    RequisitionToolbar,
    RequisitionFaxForm,
    BaseTransitionExpand,
    // RequisitionPdfViewer,
    BaseModalWindow,
  },
  setup() {
    const { 
      attachmentsUrl,
      releaseToConnectUrl,
      printUrl,
      deleteUrl,
      faxInfomationUrl,
      getFaxStatus,
      getIsReleasedToPatient,
      getOutgoingFaxRecipients,
      isSaving,
      isFaxing,
      getIsFaxMenuOpen,
      getXfdfString,
      getPdfFormTemplateId,
      getIsLoading,
      getIsRequisitionModalOpen,
      getPdfFormIdToView,
      toggleIsFaxMenuOpen,
      initializeAttachments,
      releaseToPatient,
      fetchFaxInfo,
      deleteRequisition,
      saveRequisition,
      printRequisition,
      sendFax,
      closeRequisitionModal
    } = useRequisitionModalManager();

    return {
      isFaxing,
      isSaving,
      attachmentsUrl,
      releaseToConnectUrl,
      printUrl,
      deleteUrl,
      faxInfomationUrl,
      getIsFaxMenuOpen,
      getIsReleasedToPatient,
      getFaxStatus,
      getOutgoingFaxRecipients,
      getXfdfString,
      getPdfFormTemplateId,
      getIsLoading,
      getPdfFormIdToView,
      getIsRequisitionModalOpen,
      initializeAttachments,
      releaseToPatient,
      fetchFaxInfo,
      toggleIsFaxMenuOpen,
      saveRequisition,
      sendFax,
      printRequisition,
      deleteRequisition,
      closeRequisitionModal
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/styles/global-styles.scss';
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  background-color:rgba(0,0,0, 0.2);
  height: 100vh;
  width: 100vw;
}
.grid-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  .web-viewer {
    flex-grow: 1;
    width: 100%;
    max-width: $ava-breakpoint-lg;
    margin: auto;
    margin-top: 1em;
  }
}
</style>
