<template>
  <div class="requisition-toolbar">
    <base-typography title> Edit Form </base-typography>
    <requisition-buttons-bar
      data-test="requisition-buttons-bar"
      :isReleasedToPatient="isReleasedToPatient"
      :attachmentsUrl="attachmentsUrl"
      :releaseToConnectUrl="releaseToConnectUrl"
      :printUrl="printUrl"
      :deleteUrl="deleteUrl"
      :faxStatus="faxStatus"
      :outgoingFaxRecipients="outgoingFaxRecipients"
      @openAttachmentsMenu="$emit('openAttachmentsMenu')"
      @hoverOutgoingFaxes="$emit('hoverOutgoingFaxes')"
      @releaseToPatient="$emit('releaseToPatient')"
      @printRequisition="$emit('printRequisition')"
      @deleteRequisition="$emit('deleteRequisition')"
      @openFaxMenu="$emit('openFaxMenu')"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import BaseButtonIcon from '@/ui-library/base-button/base-button-icon.vue';
import BaseTypography from '@/ui-library/base-typography/base-typography.vue';
import { FaxStatus } from '@/types/fax-status.type';
import RequisitionToolbarButton from './requisition-toolbar-button.vue';
import SendToConnectIcon from './send-to-connect-icon.vue';
import BaseTooltip from '@/ui-library/base-tooltip/base-tooltip.vue';
import { OutgoingFaxRecipient } from '../../types/outgoing-fax-recipient.interface';
import OutgoingFaxesIcon from './outgoing-faxes-icon.vue';
import RequisitionButtonsBar from './requisition-buttons-bar.vue';

export default defineComponent({
  emits: [
    'close', 
    'openFaxMenu',
    'openAttachmentsMenu',
    'hoverOutgoingFaxes',
    'releaseToPatient',
    'printRequisition',
    'deleteRequisition',
    'clickFaxMenu'
  ],
  props: {
    pdfFormId: {
      type: Number,
      required: true
    },
    outgoingFaxRecipients: {
      type: Array as PropType<OutgoingFaxRecipient[]>,
      default: () => []
    },
    isReleasedToPatient: {
      type: Boolean,
      default: true
    },
    attachmentsUrl: {
      type: String,
      required: false
    },
    releaseToConnectUrl: {
      type: String,
      required: false
    },
    printUrl: {
      type: String,
      required: false
    },
    deleteUrl: {
      type: String,
      required: false
    },
    faxStatus: {
      type: String as PropType<FaxStatus>,
      required: false,
      default: null
    }
  },
  components: {
    BaseButtonIcon,
    BaseTypography,
    RequisitionToolbarButton,
    SendToConnectIcon,
    BaseTooltip,
    OutgoingFaxesIcon,
    RequisitionButtonsBar
  },
  setup() {
    return {};
  }
});
</script>

<style lang="scss" scoped>
.requisition-toolbar {
  display: flex;
  background-color: #50B7C2;
  padding: 0.5em;
  justify-content: space-between;
}
</style>