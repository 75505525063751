<template>
  <i 
    class="fa fa-television cursor-pointer tooltipster" 
    title="Open Ava Video Link" 
    @click="patientChartStore.toggleVideoLink" 
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { usePatientChartStore } from '../../../store/use-patient-chart-store';

export default defineComponent({
  name: 'ClickableVideoLinkIcon',
  setup() {
    const patientChartStore = usePatientChartStore();
    return {
      patientChartStore
    };
  }
});
</script>
