import { defineComponent, onMounted, nextTick, createApp } from 'vue';
import { transitionToVueInitializers } from '@/utils/transition-to-vue-initializers';
import { useInitializeVideoLink } from '@/features/patient-chart/features/video-link/composition/use-initialize-video-link';
import ClickableVideoLinkIcon from '@/features/patient-chart/features/video-link/components/clickable-video-link-icon.vue';
import PatientChart from '@/features/patient-chart/patient-chart.vue';
import LabsPanel from '@/features/patient-chart/features/requisitions/features/labs-panel/labs-panel.vue';
import InvestigationsPanel from '@/features/patient-chart/features/requisitions/features/investigations-panel/investigations-panel.vue';

export default defineComponent({
  name: 'base-vue-app',
  setup(props) {
    /**
     * As part of transition to Vue, we need to initialize 
     * a set of scripts to ensure continuity
    */
    onMounted(() => {
      ClickableVideoLinkIcon
      transitionToVueInitializers()
      nextTick(() => {
        useInitializeVideoLink();
      })
    });
    
  },
  components: {
    // app/views/patients/show.html.erb
    PatientChart,
    // app/views/patients/_demographics.html.erb
    ClickableVideoLinkIcon,
    // app/views/patients/show.html.erb
    LabsPanel,
    // app/views/patients/show.html.erb
    InvestigationsPanel
  }
});
