<template>
  <base-button-icon v-bind="$attrs" class="handle-button">
    <i class="fa fa-bars" />
  </base-button-icon>
</template>

<script setup lang="ts">
import BaseButtonIcon from '../base-button/base-button-icon.vue';

</script>

<style lang="scss" scoped>
  .handle-button {
    cursor: move;
  }
</style>