import { onMounted, onUnmounted, ref } from 'vue';

export function useResizeWindowHeightAndWidth() {
  const innerHeight = ref<number>(0);
  const innerWidth = ref<number>(0);
  const setInnerHeightValue = () => {
    if (window) {
      innerHeight.value = window.innerHeight;
    }
  };
  const setInnerWidthValue = () => {
    if (window) {
      innerWidth.value = window.innerWidth;
    }
  };
  const setWindowSize = () => {
    setInnerHeightValue();
    setInnerWidthValue();
  };
  onMounted(() => {
    setInnerHeightValue();
    setInnerWidthValue();
    window.addEventListener('resize', setWindowSize);
  });
  onUnmounted(() => window.removeEventListener('resize', setInnerHeightValue));

  return {
    innerHeight,
    innerWidth
  };
}
