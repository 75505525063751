import { storeToRefs } from 'pinia';
import { CreateFilledPdfFormRequestProps } from '../types/create-filled-pdf-form-request-props.interface';
import { UseNewRequisitionFormStore } from '../types/new-requisition-form-store.type';
import { useViewRequisitionStore } from '../store/use-view-requisition-store';
import { FilledPdfCategory } from '../types/filled-pdf-category.type';

export function useRequisitionViewer(
  newRequisitionFormStore: UseNewRequisitionFormStore,
  viewRequisitionStore: ReturnType<typeof useViewRequisitionStore>,
  FilledPdfCategory: FilledPdfCategory
) {
  const {
    getSelectedClinicId,
    getSelectedEmployeeId,
    getSelectedPdfFormTemplateId,
    getIsNewRequisitionFormFilled,
  } = storeToRefs(newRequisitionFormStore);

  const initializeNewRequisitionForm = () =>
    viewRequisitionStore.initializeNewRequisitionForm(
      {
        category: FilledPdfCategory,
        clinicId: getSelectedClinicId.value,
        employeeId: getSelectedEmployeeId.value,
        pdfFormTemplateId: getSelectedPdfFormTemplateId.value,
      } as CreateFilledPdfFormRequestProps,
      getIsNewRequisitionFormFilled.value
    );
  const closeRequisitionModal = viewRequisitionStore.closeRequisitionModal;

  return {
    closeRequisitionModal,
    initializeNewRequisitionForm
  };
}