import { useRequisitionFaxFormStore } from '../../features/patient-chart/features/requisitions/features/requisition-modal/features/requisition-fax-form/store/use-requisition-fax-form-store';
import { decodeHtmlEntities } from '../../utils/text-helpers';
import { GlobalStateFacade } from './global-state-facade.interface';

/**
 * In order to couple vue app and rails app we use a globalStateFacade that
 * is available on the global instance. The methods on the facade will trigger
 * state change that will affect components listening to the state.
 * 
 * No "logic" should exist here 
 * createGlobalStateFacade methods should only use stores and their methods  
 */

export function createGlobalStateFacade(): GlobalStateFacade {
  return {
    patientChart: {
      /**
       * selectRequisitionFaxConsultant method is used in:
       * /app/views/consultants/select.js.erb
       * /app/views/consultants/select.js+phone.erb
       */
      selectRequisitionFaxConsultant(label: string, faxNumber: string) {
        const store = useRequisitionFaxFormStore();
        store.setLabel(decodeHtmlEntities(label));
        store.setFaxNumber(decodeHtmlEntities(faxNumber));
      },
    },
  };
}
