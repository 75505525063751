import http from '@/http/http';
import { AxiosPromise } from 'axios';
import { CreateFilledPdfFormRequestProps } from '../types/create-filled-pdf-form-request-props.interface';
import { FilledPdfCategory } from '../types/filled-pdf-category.type';
import { NewRequisitionState } from '../types/new-requisition-state.interface';
import { RequisitionListItem } from '../types/requisition-list-item.interface';
import { RequisitionData } from '../types/requisition-data.interface';

import { 
  createEndpointForEditingPatientRequisition, 
  createEndpointForPatientRequisitions, 
  createEndpointForNewPatientRequisition
} from './requisition-service-helpers';

export function getFilledPdfs(
  category: FilledPdfCategory
): AxiosPromise<RequisitionListItem[]> {
  return http.get<RequisitionListItem[]>(createEndpointForPatientRequisitions(), {
    params: {
      format: 'json',
      category
    }
  })
}

export function getDataToPrepareRequisitionForm(
  category: FilledPdfCategory
): AxiosPromise<NewRequisitionState> {
  return http.get<NewRequisitionState>(createEndpointForNewPatientRequisition(), {
    params: {
      format: 'json',
      category
    }
  })
}

export function getRequistionData(
  filledPdfId: number
): AxiosPromise<RequisitionData> {
  return http.get(
    createEndpointForEditingPatientRequisition(filledPdfId), 
    { 
      params: {
        format: 'json'
      }
    }
  )
}

export function createFilledPdfForm(
  props: CreateFilledPdfFormRequestProps
): AxiosPromise {
  return http.post(createEndpointForPatientRequisitions(), {
    filled_pdf_form: {
      pdf_form_id: props.pdfFormTemplateId,
      employee_id: props.employeeId,
      clinic_id: props.clinicId
    }
  },
  {
    params: {
      format: 'json'
    }
  });
}