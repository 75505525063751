<template>
  <div class="form-editor-cancel-submit-buttons">
    <slot />
    <base-button warning @click="$emit('close')"> cancel </base-button>
    <base-button primary @click="$emit('submit')"> submit </base-button>
  </div>
</template>

<script setup lang="ts">
import BaseButton from '@/ui-library/base-button/base-button.vue';

defineEmits(['close', 'submit']);

</script>

<style lang="scss" scoped>
.form-editor-cancel-submit-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1em;
}
</style>