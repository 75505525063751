export const getUrlQueryParameter = (
  queryParams: URLSearchParams,
  key: string
): boolean | string | number | null => {
  let result;
  const value = queryParams.get(key) || 'null';
  try {
    result = JSON.parse(value);
  } catch(e) {
    result = queryParams.get(key);
  }
  return result;
}

export const replaceQueryParamsOnPathname = (
  locationPathname: string,
  newQueryParams: URLSearchParams
): string => {
  return Array.from(newQueryParams.entries()).length
    ? `${locationPathname.split('?')[0]}?${newQueryParams.toString()}`
    : locationPathname
}