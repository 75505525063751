window.addEventListener(
  'vue-is-mounted',
  () => {
    if ($('.datepicker').datepicker) {
      $('.datepicker').datepicker({
        showButtonPanel: false, 
        changeMonth: true,
        changeYear: false,
        dateFormat: 'yyyy-MM-dd'
      }).inputmask("9999-aaa-99", {placeholder: 'YYYY-MMM-DD'});
    }
  },
  { once: true }
);
