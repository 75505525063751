<template>
  <patient-chart-panel-header class="labs-panel-header">
    <template #title>      
      <slot />
    </template>
    <template #options>
      <base-button-icon 
        data-testid="view-history-button" 
        @click="$emit('viewHistory')"
      >
        <i class="fa fa-history fa-lg" />
      </base-button-icon>
      <base-button-icon 
        data-testid="view-new-requisition-form-button"
        @click="() => $emit('newRequisition')"
      >
        <i class="fa fa-lg fa-plus" />
      </base-button-icon>
    </template>
  </patient-chart-panel-header>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import PatientChartPanelHeader from '@/features/patient-chart/components/panel/patient-chart-panel-header.vue';
import BaseButtonIcon from '@/ui-library/base-button/base-button-icon.vue';

export default defineComponent({
  emits: ['viewHistory', 'newRequisition'],
  components: { 
    PatientChartPanelHeader,
    BaseButtonIcon 
  }
});
</script>