// AVA Macro DSL
// the << text here >> is a 'phraseSelector'
//
// For lab lookup the syntax is:
// <<labs:hba1c>>
// For medication lookup the syntax is:
// <<medications:all>>
// For vitals lookup the syntax is:
// <<vitals:bp>>

export function initCustomMacros($) {

  window.addEventListener('vue-is-mounted', (function(e) {
    $('body').on('keydown', '.macro-textarea', event => {
      const actionKeys = ['Tab', 'Enter', 'ArrowDown', 'ArrowUp', ' ']
      if (!actionKeys.includes(event.key)) { 
        removeMacroMultiSelect()
        return event
        }
      // Tab Key
      if (event.key === 'Tab') {
        // Remove Menu if it is present
        removeMacroMultiSelect()
        let phrase = highlightPhraseSelector(event)
        if (phrase) {
          event.preventDefault()
          launchCorrectBox(event)
        }
      }
    });
    
    $('body').on('keydown', '.macro-textarea', event => {
      // Enter Key for Selected Text
      if (event.key === 'Enter') {
        if (macroMultiSelectPresent()) {
          event.preventDefault()
          commitMacroChanges(event.currentTarget)
          return false
        } else if (macroMultiSelectInfoPresent()) {
          acceptSelectedText(event)
          event.preventDefault()
          return false
        }
      }
    });
    
    $('body').on('keydown', '.macro-textarea', event => {
      // Down Key for Options
      if (event.key === 'ArrowDown') {
        // If box is open prevent 'unselecting' with the arrow keys
        if (macroMultiSelectPresent()) {
          event.preventDefault()
          moveMultiSelectFocus(1)
          return false
        } else {
          const launch = launchMultiSelect(event)
          if (launch) { event.preventDefault() }
        }
      }
    });
    
    $('body').on('keydown', '.macro-textarea', event => {
      // Up Key Options
      if (event.key === 'ArrowUp') {
        // If box is open prevent 'unselecting' with the arrow keys
        if (macroMultiSelectPresent()) {
          event.preventDefault()
          moveMultiSelectFocus(-1)
          return false
        }
      }
    });
    
    $('body').on('keydown', '.macro-textarea', event => {
      // Space Key Options
      if (event.key === ' ') {
        // If box is open prevent 'unselecting' with the arrow keys
        if (macroMultiSelectPresent()) {
          event.preventDefault()
          selectFocussedMacro()
          return false
        }
      }
    });
  }), { once: true });
}


// Bits
const phraseSelectorRegex = /<<.+?>>/g

const getSubstring = (el, shift) => {
	const inputText = $(el).val()
	let outputText = ''

	if (shift === true) {
		outputText = inputText.substring(0, el.selectionStart)
	} else {
		outputText = inputText.substring(el.selectionEnd)
	}
	return outputText
}

const getSubstringIndex = (string, phraseMatches, shift) => {
	// Return an array of index [start, end]
	let selection = ''
	let start = 0
	let end = 0
	// Return false early if no matches
	if (!phraseMatches) {
		return [0, 0]
	}
	// Get correct selection
	if (shift) {
		selection = phraseMatches[phraseMatches.length - 1]
		start = string.lastIndexOf(selection)
		end = start + selection.length
	} else {
		selection = phraseMatches[0]
		start = string.indexOf(selection)
		end = start + selection.length
	}
	return [start, end]
}

const highlightPhraseSelector = (event) => {
	// Set Const
	const el = event.currentTarget
	const shift = event.shiftKey
	// Get Text
	const potentialText = getSubstring(el, shift)
	const phraseMatches = potentialText.match(phraseSelectorRegex)
	// Return unless matches
	if (!phraseMatches) {
		return false
	}
	// Get Text
	const substringIndex = shift ? 0 : el.selectionEnd
	const indexArray = getSubstringIndex(potentialText, phraseMatches, shift)
	const finalIndex = [indexArray[0] + substringIndex, indexArray[1] + substringIndex]
	// Select Text
	el.setSelectionRange(finalIndex[0], finalIndex[1])
	return true
}

const selectNext = (event) => {
	const el = event.currentTarget
	const potentialText = getSubstring(el, false)
	const phraseMatches = potentialText.match(phraseSelectorRegex)
	// Return unless matches
	if (!phraseMatches) {
		return false
	}
	// Get Text
	const substringIndex = el.selectionEnd
	const indexArray = getSubstringIndex(potentialText, phraseMatches, false)
	const finalIndex = [indexArray[0] + substringIndex, indexArray[1] + substringIndex]
	el.setSelectionRange(finalIndex[0], finalIndex[1])
	launchCorrectBox(event)
	return true
}

const acceptSelectedText = (event) => {
	const el = event.currentTarget
	const length = (el.selectionEnd - el.selectionStart)
	const textAreaText = $(el).val()
	// If no selection return false
	if (length === 0) {
		return false
	}
	const selectionText = textAreaText.substring(el.selectionStart, el.selectionEnd)
	const cleanSelection = selectionText.replace('<<', '').replace('>>', '')
	const cleanText = textAreaText.replace(selectionText, cleanSelection)
	const finalCaretPosition = el.selectionEnd - 4
	$(el).val(cleanText)
	el.setSelectionRange(finalCaretPosition, finalCaretPosition)
	removeMacroMultiSelect()
	selectNext(event)
	return true
}

const launchMultiSelect = (event) => {
	const el = event.currentTarget
	const textAreaText = $(el).val()
	const selectionText = textAreaText.substring(el.selectionStart, el.selectionEnd)
	const conjunctionCharacter = setConjunctionCharacter(selectionText)
	const remoteUrl = setRemoteUrl(selectionText)
	const conjunction = setConjunction(conjunctionCharacter)
	// Do not launch if nothing is selected or selection is not between phraseSelectors
	if (selectionText.length < 1) { return false }
	// Do not launch if no & symbol
	if (!selectionText.includes(conjunctionCharacter)) { return false }
	const position = getCursorXY(el, el.selectionEnd)
	const top  = position.y + (el.selectionEnd * 0.3)
	const left = position.x + 15
	const options = selectionText.replace('<<', '').replace('>>', '').split(conjunctionCharacter).filter((a) => a);
	const optionsWindowDiv = buildMultiSelect(options, conjunction, remoteUrl)
	$('#layout-content').append(optionsWindowDiv)
	$('#macro-multi-select').css('top', top).css('left', left)
	focusFirstMacroMultiSelect()
	return true
}

const buildMultiSelect = (options, conjunction, remoteUrl = null) => {
	let optionsData = ''
	if (!!remoteUrl) {
		options = getOptionsFromRemoteUrl(remoteUrl, options)
		optionsData = buildLoader()
	} else {
		optionsData = buildOptions(options)
	}
	return `<div id="macro-multi-select" data-conjunction="${conjunction}">
			<p class="info-text">
				Space - toggle text<br>
				Enter - save checked text<br>
			</p>
			${optionsData}
		</div>`
}

const buildOptions = (options) => {
	let optionsString = ''
	options.forEach(function (option, index) {
		optionsString += `<p class="macro-unselected macro-object">
			<i class="fa fa-times macro-icon"></i>&nbsp;
			${option.trim()}
		</p>`
	});
	return optionsString
}

const getOptionsFromRemoteUrl = (remoteUrl, data) => {
	const patientId = $('#patient-chart').data('patient-id');
	$.ajax({
		url: remoteUrl,
		data: {
			search: data[0],
			patient_id: patientId
		},
		dataType: 'json',
		success: (data) => {
			$('#macro-multi-select').find('.loader').remove();
			$('#macro-multi-select').append(buildOptions(data.values));
			focusFirstMacroMultiSelect();
		}
	})
}

const buildLoader = () => {
	return `<span class="loader">
		<i class="fa fa-spinner fa-spin"></i>
	</span>`
}

const macroMultiSelectPresent = () => {
	return !!document.getElementById('macro-multi-select')
}

const macroMultiSelectInfoPresent = () => {
	return !!document.getElementById('macro-multi-select-info')
}

const removeMacroMultiSelectOnClick = () => {
	$('body').on('click', function(e) {
		removeMacroMultiSelect()
	});
}

const focusFirstMacroMultiSelect = () => {
	$('#macro-multi-select').find('p.macro-object').first().addClass('macro-focussed')
}

const removeMacroMultiSelect = () => {
	$('#macro-multi-select').remove();
	$('#macro-multi-select-info').remove();
}

const moveMultiSelectFocus = (step) => {
	const currentIndex = $('#macro-multi-select').find('.macro-object').index($('.macro-focussed'))
	const maxIndex = $('#macro-multi-select').find('.macro-object').length - 1
	let newIndex = currentIndex + step
	// Do not allow to arrow off the menu
	if (newIndex < 0) {
		newIndex = 0
	} else if (newIndex > maxIndex) {
		newIndex = maxIndex
	}
	$('#macro-multi-select').children().removeClass('macro-focussed')
	$('#macro-multi-select').find('.macro-object').eq(newIndex).addClass('macro-focussed')
}

const selectFocussedMacro = () => {
	const focussedEl = $('#macro-multi-select').find('.macro-focussed')
	const icon = focussedEl.find('.macro-icon')
	if (focussedEl.hasClass('macro-unselected')) {
		focussedEl.removeClass('macro-unselected')
		focussedEl.addClass('macro-selected')
		icon.removeClass('fa-times')
		icon.addClass('fa-check')
	} else if (focussedEl.hasClass('macro-selected')) {
		focussedEl.addClass('macro-unselected')
		focussedEl.removeClass('macro-selected')
		icon.addClass('fa-times')
		icon.removeClass('fa-check')
	}
}

const commitMacroChanges = (el) => {
	// Set Const
	const initialText = $(el).val()
	const caretStart = el.selectionStart
	const caretEnd = el.selectionEnd
	const newPhrase = getNewPhrase()
	const finalCaretPosition = caretStart + newPhrase.length
	// Change text
	let newText = initialText.substring(0, caretStart)
	newText += newPhrase
	newText += initialText.substring(caretEnd)
	$(el).val(newText)
	el.selectionStart = finalCaretPosition
	el.selectionEnd = finalCaretPosition
	$('#macro-multi-select').remove();
}

const getNewPhrase = () => {
	let array = []
	const selected = $('#macro-multi-select').find('.macro-selected')
	const conjunction = $('#macro-multi-select').data('conjunction')
	selected.each(function(index) {
		array.push($(this).text().trim())
	});

	let length = array.length
	let outputString = ''
	array.forEach(function(text, index) {
		outputString += text
		if (length === 2) { outputString += ` ${conjunction} ` }
		if (length > 2) { outputString += ', ' }
		length -= 1
	});
	return outputString
}

const setConjunctionCharacter = (string) => {
	let output = ''
	if (string.includes('&')) {
		output = '&'
	} else if (string.includes('#')) {
		output = '#'
	} else if (string.includes('labs:')) {
		output = 'labs:'
	} else if (string.includes('medications:')) {
		output = 'medications:'
	} else if (string.includes('vitals:')) {
		output = 'vitals:'
	} else {
		output = '&'
	}
	return output
}

const setConjunction = (string) => {
	let output = ''
	if (string == '&') {
		output = 'and'
	} else if (string == '#') {
		output = 'or'
	} else {
		output = 'and'
	}
	return output
}

const setRemoteUrl = (string) => {
	let output = ''
	if (string.includes('labs:')) {
		output = '/macros/labs'
	}	else if (string.includes('medications:')) {
		output = '/macros/medications'
	}	else if (string.includes('vitals:')) {
		output = '/macros/vitals'
	} else {
		output = null
	}
	return output
}

const launchInfoBox = (event) => {
	const el = event.currentTarget
	const textAreaText = $(el).val()
	const selectionText = textAreaText.substring(el.selectionStart, el.selectionEnd)
	const position = getCursorXY(el, el.selectionEnd)
	const top  = position.y
	const left = position.x - (selectionText.length * 6.15)
	const infoDiv = buildInfo()
	$('#layout-content').append(infoDiv)
	$('#macro-multi-select-info').css('top', top).css('left', left)
	return true
}

const buildInfo = () => {
	return `<div id="macro-multi-select-info">
		<p class="info-text">
			Enter - Keep highlighted text<br>
			Delete/Backspace - remove highlighted text<br>
		</p>
	</div>`
}

const launchCorrectBox = (event) => {
	// Load Multiselect
	const launch = launchMultiSelect(event)
	if (!launch) {
		// Load Info
		launchInfoBox(event)
	}
	removeMacroMultiSelectOnClick()
}

const getCursorXY = (input, selectionPoint) => {
	const inputX = $(input).offset().left
	const inputY = $(input).offset().top
  // create a dummy element that will be a clone of our input
  const div = document.createElement('div')
  // get the computed style of the input and clone it onto the dummy element
  const copyStyle = getComputedStyle(input)
  for (const prop of copyStyle) {
    div.style[prop] = copyStyle[prop]
  }
  // we need a character that will replace whitespace when filling our dummy element if it's a single line <input/>
  const swap = '.'
  const inputValue = input.tagName === 'INPUT' ? input.value.replace(/ /g, swap) : input.value
  // set the div content to that of the textarea up until selection
  const textContent = inputValue.substr(0, selectionPoint)
  // set the text content of the dummy element div
  div.textContent = textContent
  if (input.tagName === 'TEXTAREA') div.style.height = 'auto'
  // if a single line input then the div needs to be single line and not break out like a text area
  if (input.tagName === 'INPUT') div.style.width = 'auto'
  // create a marker element to obtain caret position
  const span = document.createElement('span')
  // give the span the textContent of remaining content so that the recreated dummy element is as close as possible
  span.textContent = inputValue.substr(selectionPoint) || '.'
  // append the span marker to the div
  div.appendChild(span)
  // append the dummy element to the body
  document.body.appendChild(div)
  // get the marker position, this is the caret position top and left relative to the input
  const { offsetLeft: spanX, offsetTop: spanY } = span
  // lastly, remove that dummy element
  // NOTE:: can comment this out for debugging purposes if you want to see where that span is rendered
  document.body.removeChild(div)
  // return an object with the x and y of the caret. account for input positioning so that you don't need to wrap the input
  return {
    x: inputX + spanX,
    y: inputY,
  }
}
