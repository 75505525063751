<template>
  <base-tooltip :description="tooltipDescription">
    <base-button-icon v-bind="$attrs" class="requisition-toolbar-button">
      <slot />
    </base-button-icon>
    <template #tooltip-content> 
      <slot name="tooltip-content" />
    </template>
  </base-tooltip>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import BaseButtonIcon from '@/ui-library/base-button/base-button-icon.vue';
import BaseTooltip from '@/ui-library/base-tooltip/base-tooltip.vue';

export default defineComponent({
  inheritAttrs: false,
  components: {
    BaseButtonIcon,
    BaseTooltip
  },
  props: {
    tooltipDescription: {
      type: String,
      default: ''
    }
  },
  setup() {
    return {}
  }
});
</script>

<style lang="scss" scoped>
.requisition-toolbar-button {
  &:hover,
  &:focus {
    background: rgba(255, 255, 255, 0.5);
  }
}
</style>