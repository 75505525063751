import { ProcessedPhoneNumber } from './types/processed-phone-number.interface';

export function formatPhone(phone?: string): string {
  if (!phone) {
    return '';
  }
  const {
    lineNumber,
    prefix,
    areaCode,
    countryCode
  } = processPhoneNumber(phone);
  const primaryNumber = `${areaCode}-${prefix}-${lineNumber}`;
  return countryCode 
    ? `${countryCode}-${primaryNumber}`
    : primaryNumber
}

function processPhoneNumber(phone: string): ProcessedPhoneNumber {
  const rawPhone = stripPhoneFormatting(phone);
  if (rawPhone.length < 10) {
    throw Error('Phone number does not have at least 10 digits');
  } else {  
    return {
      lineNumber: rawPhone.slice(rawPhone.length - 4, rawPhone.length),
      prefix: rawPhone.slice(rawPhone.length - 7, rawPhone.length - 4),
      areaCode: rawPhone.slice(rawPhone.length - 10, rawPhone.length - 7),
      countryCode:
        rawPhone.length > 10 ? rawPhone.slice(0, rawPhone.length - 10) : null,
    };
  }
}

function stripPhoneFormatting(fax: string): string {
  return fax.trim().replace(/\(|\)|-| /g, '');
}
