import http from '@/http/http';
import { AxiosPromise } from 'axios';
import { FaxRequisitionFormDataResponseDto } from '../types/fax-requisition-form-data-response-dto.interface';
import { createEndpointForFaxRequisitionForm, createEndpointForFaxRequisitionFormData } from './requisition-fax-service-helpers';
import { FaxRequisitionRequestPayload } from './types/fax-requisition-request-payload.interface';

export const getFaxRequisitionFormData = (
  pdfFormId: number
): AxiosPromise<FaxRequisitionFormDataResponseDto> => {
  return http.get<FaxRequisitionFormDataResponseDto>(
    createEndpointForFaxRequisitionFormData(pdfFormId),
    {
      params: { format: 'json' },
    }
  );
};

export const createRequisitionFax = (
  formData: FaxRequisitionRequestPayload
): AxiosPromise => {
  return http.callPostXmlPayload(
    createEndpointForFaxRequisitionForm(formData.pdfFormId),
    {
      recipient: {
        name: formData.label,
        fax_number: formData.faxNumber
      },
      onlyFirstPage: formData.onlyFirstPage
    }
  )
};
