import { createNoSubstitutionTemplateLiteral } from "typescript";
import consumer from "../consumer"

$(document).ready(function() {
  if ($('#footer-messenger').length > 0) {
    let clinicId = $('#messenger-index-button').data('clinic-id');
    let channelId = `{\"channel\":\"EmrChannels::ClinicSessionStatusChannel\",\"clinic_id\":${clinicId}}`
    if (isSubscribed(consumer, `cid_${clinicId}_online_employees`)) {
      console.log('already subscribed to ClinicSessionStatusChannel')
      return true;
    }
    consumer.subscriptions.create(
      {
        channel: 'EmrChannels::ClinicSessionStatusChannel',
        clinic_id: clinicId
      },
      {
        initialized() {
          console.log('initializing')
          // this.update = this.update.bind(this)
        },
  
        received(data) {
          console.log("ClinicSessionStatusChannelData", data)
        },
  
        // Call when subscription is ready for use on the server
        connected() {
          console.log("ClinicSessionStatusChannel Connected!")
          // this.install()
          // this.update()
        },
  
        // Called when the WebSocket connection is closed
        // disconnected() {
        //   this.uninstall()
        // },
  
        // // Called when the subscription is rejected by the server
        // rejected() {
        //   this.uninstall()
        // },
  
        // update() {
        //   this.documentIsActive ? this.appear() : this.away()
        // },
  
        // appear() {
        //   // Calls 'ClinicSessionStatusChannel#appear(data)' on the server
        //   this.perform("appear", { appearing_on: this.appearingOn })
        // },
  
        // away() {
        //   this.perform("away")
        // },
  
        // install() {
        //   window.addEventListener("focus", this.update)
        //   window.addEventListener("blur", this.update)
        //   window.addEventListener("turbolinks:load", this.update)
        //   window.addEventListener("visibilitychange", this.update)
        // },
  
        // uninstall() {
        //   window.removeEventListener("focus", this.update)
        //   window.removeEventListener("blur", this.update)
        //   document.removeEventListener("turbolinks:load", this.update)
        //   document.removeEventListener("visibilitychange", this.update)
        // },
  
        // get documentIsActive() {
        //   return document.visibilityState === "visible" && document.hasFocus()
        // },
  
        // get appearingOn() {
        //   const element = document.querySelector("[data-appearing-on]")
        //   return element ? element.getAttribute("data-appearing-on") : null
        // }
      }
    );
  }  
});

function isSubscribed(consumer, channelId) {
  return consumer.subscriptions.findAll(channelId).length > 0
}
