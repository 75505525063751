// import { WebViewerInstance } from '@pdftron-8.1.0/webviewer';
import { storeToRefs } from 'pinia';
import { provide, ref, watchEffect } from 'vue';
import { useViewRequisitionStore } from '../../../store/use-view-requisition-store';
import { FaxRequisitionRequestPayload } from '../features/requisition-fax-form/services/types/fax-requisition-request-payload.interface';
import { useRequisitionModalStore } from '../store/use-requisition-modal-store';
import provideInjectSymbols from '../types/provide-inject-symbols';
import { useLabsPanelRequisitionHistoryStore } from '../../labs-panel/store/use-labs-panel-requisition-history-store';
import { useInvestigationsPanelRequisitionHistoryStore } from '../../investigations-panel/store/use-investigations-panel-requisition-history-store';

export function useRequisitionModalManager() {

  const pdfViewerInstance = ref<any>();
  const setPdfViewerInstance = (instance: any) => {
    pdfViewerInstance.value = instance;
  };

  provide(provideInjectSymbols.PdfViewer, null);
  provide(provideInjectSymbols.SetPdfViewer, setPdfViewerInstance);

  const requisitionModalStore = useRequisitionModalStore();
  const labReqsStore = useLabsPanelRequisitionHistoryStore();
  const investigationReqsStore = useInvestigationsPanelRequisitionHistoryStore();
  
  const {
    isFaxing,
    isSaving,
    attachmentsUrl,
    releaseToConnectUrl,
    printUrl,
    deleteUrl,
    faxInfomationUrl,
    getFaxStatus,
    getIsReleasedToPatient,
    getOutgoingFaxRecipients,
    getPdfFormTemplateId,
    getXfdfString,
    getIsLoading,
    getIsFaxMenuOpen
  } = storeToRefs(requisitionModalStore);

  const viewRequisitionStore = useViewRequisitionStore();

  const { getIsRequisitionModalOpen, getPdfFormIdToView } =
    storeToRefs(viewRequisitionStore);

  const saveRequisition = () =>
    requisitionModalStore.saveRequisition(getPdfFormIdToView.value, pdfViewerInstance.value);

  const printRequisition = async () => {
    await saveRequisition();
    requisitionModalStore.openRequisitionInNewTab();
  };

  const sendFax = async (payload: FaxRequisitionRequestPayload) => {
    await saveRequisition();
    requisitionModalStore.sendFax(payload);
  };

  const deleteRequisition = async () => {
    if (await requisitionModalStore.deleteRequisition()) {
      const pdfId = requisitionModalStore.getFilledPdfId;
      labReqsStore.removeRequisitionByFilledPdfId(pdfId);
      investigationReqsStore.removeRequisitionByFilledPdfId(pdfId);
      viewRequisitionStore.closeRequisitionModal();
    }
  }
  
  watchEffect(() => {
    if (getPdfFormIdToView.value) {
      requisitionModalStore.getRequisitionData(getPdfFormIdToView.value);
    }
  });

  return {
    attachmentsUrl,
    releaseToConnectUrl,
    printUrl,
    deleteUrl,
    isSaving,
    isFaxing,
    faxInfomationUrl,
    getIsFaxMenuOpen,
    getFaxStatus,
    getIsReleasedToPatient,
    getOutgoingFaxRecipients,
    getPdfFormTemplateId,
    getXfdfString,
    getIsLoading,
    getIsRequisitionModalOpen,
    getPdfFormIdToView,
    saveRequisition,
    printRequisition,
    sendFax,
    deleteRequisition,
    toggleIsFaxMenuOpen: requisitionModalStore.toggleIsFaxMenuOpen,
    initializeAttachments: requisitionModalStore.initializeAttachments,
    releaseToPatient: requisitionModalStore.releaseToPatient,
    fetchFaxInfo: requisitionModalStore.createThrottledFetchFaxInfo(),
    closeRequisitionModal: viewRequisitionStore.closeRequisitionModal,
  };
}
