// scss at app/assets/stylesheets/ava/views/page-loader/index.scss
// id page-loader is in layouts application.html.erb, admin.html.erb

window.addEventListener('vue-is-mounted', () => {
  removeElement(document.getElementById('page-loader'))
});

function removeElement(el: HTMLElement | null, speedInMs = 200) {
  const seconds = speedInMs/1000;
  if (el) {
    el.style.transition = "opacity "+seconds+"s ease";
    el.style.opacity = '0';
    setTimeout(() => el.remove(), speedInMs);
  }
}