<template>
  <div class="patient-chart-panel-header">
    <base-typography title>
      <slot name="title" />
    </base-typography>
    <div class="options">
      <slot name="options" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import BaseTypography from '@/ui-library/base-typography/base-typography.vue';

export default defineComponent({
  components: { BaseTypography },
  setup() {
    return {};
  }
});
</script>

<style lang="scss" scoped>
.patient-chart-panel-header {
  display: flex;
  background-color: #FCD7D2;
  justify-content: space-between;
  padding: 5px 10px;
  margin-top: 5px;
  .title, 
  .options {
   display: flex;
   align-items: center;
   gap: 2px; 
  }
  .options {
    padding-right: 2px;
  }
}
</style>