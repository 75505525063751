// app/javascript/channels/consumer.js
// Action Cable provides the framework to deal with WebSockets in Rails.
// Copied from the Rails Guide :D
// You can generate new channels where WebSocket features live using the `bin/rails generate channel` command.

import { createConsumer } from "@rails/actioncable"
import { consumers } from "stream"

export default createConsumer()

// For proof of concept skip the dynamic URL stuff - will need this for AnyCable
// Specify a different URL to connect to
// createConsumer('https://ws.example.com/cable')

// Use a function to dynamically generate the URL
// createConsumer(getWebSocketURL)

// function getWebSocketURL() {
//   const token = localStorage.get('auth-token')
//   return `https://ws.example.com/cable?token=${token}`
// }
