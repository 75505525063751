
import { Ref } from 'vue';
import {
  Participant,
  VideoTrack,
  RemoteParticipant,
  Room,
  RemoteTrack,
  LocalVideoTrack
} from 'twilio-video';
import { VideoConnectionOptions } from './video-connection-options.interface';
import { getUrlQueryParameter } from '../../../../../utils/route-helpers';

export const shouldOpenVideoLinkAccordingToQueryParam = (
  queryParams: URLSearchParams
): boolean => getUrlQueryParameter(queryParams, 'video-link') === true;

export const generateRoomName = (): string => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < 10; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result;
};

export const createVideoConnectionOptions = (
  roomCode: string
): VideoConnectionOptions => ({
  name: roomCode,
  audio: true,
  maxAudioBitrate: 12000,
  video: { 
    height: 360, 
    frameRate: 18, 
    width: 640 
  }
});

export const mountTrack = (
  track: VideoTrack | RemoteTrack | null,
  participant: Participant,
  remoteTrackRef: Ref<HTMLElement | undefined>
) => {
  if (track) {
    const div = document.createElement('div');
    div.id = participant.sid;
    // @ts-expect-error track does have attach function: 
    // https://www.twilio.com/docs/video/javascript-v2-getting-started#working-with-remote-participants
    div.appendChild(track.attach());
    remoteTrackRef.value?.appendChild(div);
  }
};

export const mountRoomParticipants = (
  participants: Map<string, RemoteParticipant>,
  remoteTrackRef: Ref<HTMLElement | undefined>
) => {
  participants.forEach(participant => {
    participant.tracks.forEach(publication => {
      mountTrack(publication.track, participant, remoteTrackRef);
    });
    participant.on('trackSubscribed', track => {
      mountTrack(track, participant, remoteTrackRef);
    });
  });
};

export const getLocalVideoTrack = (room: Room) => {
  return Array.from(room.localParticipant.videoTracks.values())[0].track;
}

export const mountLocalTrackToDomRef = (
  domRef: Ref<HTMLElement | undefined>,
  localTrack?: LocalVideoTrack
) => {
  if (localTrack) {
    domRef.value?.appendChild(localTrack.attach());
  }
}

const mountTracksFromParticipant = (
  participant: RemoteParticipant,
  remoteTrackRef: Ref<HTMLElement | undefined>
) =>
  participant.tracks.forEach(publication => {
    if (publication.isSubscribed && publication.track) {
      mountTrack(publication.track, participant, remoteTrackRef);
    }
  });

export const mountParticipant = (
  participant: RemoteParticipant, 
  remoteTrackRef: Ref<HTMLElement | undefined>
) => {
  mountTracksFromParticipant(participant, remoteTrackRef);
  participant.on('trackSubscribed', track => {
    mountTrack(track, participant, remoteTrackRef);
  });
}

export const removeTrack = (participant: RemoteParticipant) => {
  document.getElementById(participant.sid)?.remove();
};

export const mutePatientMicrophone = (room?: Room) => {
  if (room) {
    room.localParticipant.audioTracks.forEach(publication => {
      publication.track.disable();
    });
  }
};

export const mutePatientVideo = (room?: Room) => {
  if (room) {
    room.localParticipant.videoTracks.forEach(publication => {
      publication.track.disable();
    });
  }
};

export const unMutePatientMicrophone = (room?: Room) => {
  if (room) {
    room.localParticipant.audioTracks.forEach(publication => {
      publication.track.enable();
    });
  }
};

export const unMutePatientVideo = (room?: Room) => {
  if (room) {
    room.localParticipant.videoTracks.forEach(publication => {
      publication.track.enable();
    });
  }
};

export const terminateLocalTracks = (room?: Room) => {
  room?.localParticipant.videoTracks.forEach(t => t.track.stop());
  room?.localParticipant.audioTracks.forEach(t => t.track.stop());
  room?.localParticipant.tracks.forEach(publication => {
    publication.unpublish();
  })
}