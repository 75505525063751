<template>
  <div class="patient-chart-panel-body">
    <base-transition-expand>
      <div v-if="isPageChosen" class="patient-chart-panel-inner-body">
        <base-loader :isLoading="isLoading" />
        <slot />
      </div>
    </base-transition-expand>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import BaseTransitionExpand from '@/ui-library/base-transition/base-transition-expand.vue';
import BaseLoader from '@/ui-library/base-loader/base-loader.vue';

export default defineComponent({
  components: { BaseTransitionExpand, BaseLoader },
  props: {
    isPageChosen: {
      type: Boolean,
      required: true
    },
    isLoading: {
      type: Boolean
    }
  }
});
</script>

<style lang="scss" scoped>
.patient-chart-panel-body {
  background: #f6f6f6;
  max-height: 150em;
  border-radius: 2px;
  .patient-chart-panel-inner-body {
    padding: 0.5em 1em 0.5em 1em;
  }
}
</style>