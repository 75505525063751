<template>
  <edit-form-item-layout 
    :item="item as FormItemDate"
    :required="['label']"
    :autoSave="autoSave"
    v-slot="{ proposedItem, errorMessageState }"
    @close="$emit('close')"
    @submit="(payload) => $emit('submit', payload)"
    @save="(payload) => $emit('save', payload)">

    <template v-if="proposedItem.category === 'date'">
      <form-editor-defaults 
        :errorMessageState="errorMessageState"
        :formId="proposedItem.id"
        :liteFormItems="liteFormItems"
        :category="proposedItem.category"
        v-model:label="proposedItem.label"
        v-model:help="proposedItem.help"
        v-model:required="(proposedItem.required as boolean)"
        v-model:display="proposedItem.display"
      />
    </template>
  </edit-form-item-layout>
</template>

<script setup lang="ts">

import { FormItemDate } from '../../../../../../../../types/patient-form-template-details';
import EditFormItemLayout from '../../layouts/edit-form-item-layout.vue';
import FormEditorDefaults from '../form-editor-defaults.vue';
import { formItemComponentDefaultPropDefinition } from '../../types/form-item-component.functions'

defineProps(formItemComponentDefaultPropDefinition())

defineEmits(['close', 'submit', 'update:item', 'save'])


</script>
